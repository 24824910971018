// src/MyForm.tsx
import React, { useEffect } from 'react';
import { Form, Input, Button, Select, Radio } from 'antd';
import ImageUpload from '../../../component/ImageUpload';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const { Option } = Select;

interface Props {
  mode: 'edit' | 'create';
  tags?: any;
  isLoading?:boolean
  initialData?: {
    id: number;
    //type?: string;
    username: string;
    englishName: string;
    //movieUrl?: string;
    //description?: string;
    //banner?: string;
    //mb_banner?: string;
    //avatar?: string;
    cover?: string;
    //tags?: string;
  };
  onSave: (data: any) => void;
}

const AnimalForm: React.FC<Props> = ({ mode, initialData, onSave, tags }) => {

  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    onSave(values);
    form.resetFields()
  };
  const handleImageUploadSuccess = (url: string, fieldName: string) => {
    form.setFieldsValue({
      [fieldName]: url
    });
  };

  useEffect(() => {
    if (initialData?.id) {
      form.setFieldsValue({
        ...initialData,
        //tags: initialData?.tags?.split(',') || []
      });
    }
  }, [initialData])

  return (
    <Form
      form={form}
      initialValues={initialData}
      onFinish={onFinish}
    >
      {mode === 'edit' ? <Form.Item name="id" label="id" >
        <Input disabled />
      </Form.Item> : null}
      {/* <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select a type!' }]}>
        <Radio.Group>
          <Radio value="日本知名女優">日本知名女優</Radio>
          <Radio value="兔子先生專屬女優素人">兔子先生專屬女優</Radio>
          <Radio value="日本美女素人">日本美女素人</Radio>
        </Radio.Group>
      </Form.Item> */}
      <Form.Item name="username" label="Username" rules={[{ required: true, message: 'Please input the username!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="englishName" label="English Name" rules={[{ required: true, message: 'Please input the englishName!' }]}>
        <Input />
      </Form.Item>
    {/*   <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please input the description!' }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="movieUrl" label="Movie Url">
        <Input />
      </Form.Item>
      <Form.Item name="banner" label="PC Banner" rules={[{ required: true, message: 'Please upload!' }]}>
        <ImageUpload initialImageUrl={initialData?.banner} onUploadSuccess={(url) => handleImageUploadSuccess(url, 'banner')} />
      </Form.Item>
      <Form.Item name="mb_banner" label="Mobile Banner" rules={[{ required: true, message: 'Please upload!' }]}>
        <ImageUpload initialImageUrl={initialData?.mb_banner} onUploadSuccess={(url) => handleImageUploadSuccess(url, 'mb_banner')} />
      </Form.Item> */}
    {/*   <Form.Item name="avatar" label="Avatar" rules={[{ required: true, message: 'Please upload!' }]}>
        <ImageUpload initialImageUrl={initialData?.avatar} onUploadSuccess={(url) => handleImageUploadSuccess(url, 'avatar')} />
      </Form.Item> */}
      <Form.Item name="cover" label="Cover" rules={[{ required: true, message: 'Please upload!' }]}>
        <ImageUpload initialImageUrl={initialData?.cover} onUploadSuccess={(url) => handleImageUploadSuccess(url, 'cover')} />
      </Form.Item>
      {/* <Form.Item name="tags" label="Tags">
        <Select mode="tags" placeholder="Select tags">
          {tags?.map((item: any) => (
            <Option key={item.id} value={item.tag}>{item.tag}</Option>
          ))}
        </Select>
      </Form.Item> */}
      <Form.Item>
        <Button type="primary" htmlType="submit" className='text-black'>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AnimalForm;

