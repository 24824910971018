import { useState, useEffect, useCallback } from "react";
import { getEventsCoverByRandom } from '../api';
//import { useNavigate } from "react-router-dom";


type EventProps = {
  title: string;
  eventDate: string;
  imgSrc: string;
  altText: string;
  eventDescC: string;
  eventDescE: string;
  manager: string;
};

const Event: React.FC<EventProps> = ({
  title,
  eventDate,
  imgSrc,
  altText,
  eventDescC,
  eventDescE,
  manager,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <article className="flex gap-5 max-md:flex-col max-md:gap-0">
      <figure className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
        <img
          loading="lazy"
          src={imgSrc}
          alt={altText}
          className="grow w-full aspect-[1.22] max-md:mt-10 max-md:max-w-full"
        />
      </figure>
      <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
        <h2 className="flex flex-col mt-7 font-medium text-orange-100 leading-[56px] max-md:mt-10 max-md:max-w-full">
          <span className={`max-md:max-w-full text-left mt-12 ${isMobile ? 'text-[20px]' : 'text-4xl'}`}>{title}</span>
          <div className="flex flex-col self-end mt-5 mr-7 max-w-full w-[389px] max-md:mr-2.5">
            <div className="self-end max-w-full border-fuchsia-600 border-solid aspect-[100] border-[3px] w-[283px]" />
            <figcaption className={`text-right whitespace-nowrap mt-16 ${isMobile ? 'text-[20px]' : 'text-2xl'}`}>{eventDate}</figcaption>
          </div>
        </h2>
        <div className="text-left">
          <p className="mt-14 text-base leading-8 text-orange-200 max-md:mt-10 max-md:max-w-full">
            {eventDescC}
          </p>
          <p className="mt-5 text-base font-light leading-8 text-orange-200 max-md:max-w-full">
            {eventDescE}
          </p>
        </div>
        <div className="flex flex-col self-end mt-8 text-base">
          <span>店长: {manager}</span>
        </div>
      </div>
    </article>
  );
};
const EventList: React.FC<{ id?: string }> = ({ id }) => {
  const [events, setEvents] = useState<EventProps[]>([]);
  const [isMobile, setIsMobile] = useState(false);

  const loadData = useCallback(async () => {
    const excludedIds = [0];
    const limit = 5;
    try {
      const res = await getEventsCoverByRandom({ limit, excludedIds });
      if (res && res.data) {
        const eventList = res.data.map((event: any) => ({
          //date: event.date || '', // 这里需要根据你的实际数据结构进行调整
          title: event.title,
          imgSrc: event.cover,
          altText: "Event image",
          eventDate: event.subtitle || '', // 这里需要根据你的实际数据结构进行调整
          eventDescC: event.description,
          eventDescE: event.en_description,
          manager: "Krueger Smith", // 假设这个字段是固定的
          //postedDate: event.date || '' // 这里需要根据你的实际数据结构进行调整
        }));
        setEvents(eventList);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  return (
    <div id={id} className="flex flex-col items-center self-stretch px-20 py-20 mt-32 w-full bg-stone-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <header className="text-xl font-medium text-center text-orange-800">
        最新活动信息
      </header>
      <h1 className="mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Events News
      </h1>
      <main className="self-stretch mt-18 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        {events.map((event, index) => (
          <div className="self-stretch mt-16 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full" key={index}>
            <Event {...event} />
          </div>
        ))}
      </main>
      <nav className="justify-center items-center px-16 py-0 mt-28 mb-10 max-w-full text-xl font-bold text-white whitespace-nowrap border-fuchsia-700 border-solid border-[3px] leading-[40px] rounded-[51.2px] w-[230px] max-md:px-5 max-md:mt-10">
        <a href="#" onClick={() => { window.location.replace('/event') }} className="block w-full h-full">More</a>
      </nav>
    </div>
  );
};

export default EventList;
/* import { useState, useEffect, useCallback } from "react";
import { getEventsCoverByRandom } from '../api';

type EventProps = {
  id: number;
  date: string;
  title: string;
  eventDate: string;
  eventDescC: string;
  eventDescE: string;
  manager: string;
  postedDate: string;
};

const Event: React.FC<EventProps> = ({
  date,
  title,
  eventDate,
  eventDescC,
  eventDescE,
  manager,
  postedDate,
}) => (
  <article className="flex gap-5 max-md:flex-col max-md:gap-0">
    <div className="flex flex-col ml-5 w-full max-md:ml-0">
      <h2 className="flex flex-col mt-7 text-4xl font-medium text-orange-100 leading-[56px] max-md:mt-10 max-md:max-w-full">
        <span className="max-md:max-w-full">{title}</span>
        <div className="flex flex-col self-end mt-5 mr-7 max-w-full w-[389px] max-md:mr-2.5">
          <div className="self-end max-w-full border-fuchsia-600 border-solid aspect-[100] border-[3px] w-[283px]" />
          <figcaption className="mt-6">{eventDate}</figcaption>
        </div>
      </h2>
      <div className="text-left">
        <p className="mt-14 text-base leading-8 text-orange-200 max-md:mt-10 max-md:max-w-full">
          {eventDescC}
        </p>
        <p className="mt-5 text-base font-light leading-8 text-orange-200 max-md:max-w-full">
          {eventDescE}
        </p>
      </div>
      <div className="flex flex-col self-end mt-8 text-base">
        <span>店长: {manager}</span>
        <time className="self-end mt-2.5">{postedDate}</time>
      </div>
    </div>
  </article>
);

const EventWall: React.FC<{ id?: string }> = ({ id }) => {
  const [events, setEvents] = useState<EventProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const limit = 2;
    try {
      const res = await getEventsCoverByRandom({ limit, excludedIds });
      if (res && res.data) {
        const eventList = res.data.map((event: any) => ({
          id: event.id,
          date: event.date || '', // 这里需要根据你的实际数据结构进行调整
          title: event.title,
          eventDate: event.date || '', // 这里需要根据你的实际数据结构进行调整
          eventDescC: event.description,
          eventDescE: event.en_description,
          manager: "Krueger Smith", // 假设这个字段是固定的
          postedDate: event.date || '' // 这里需要根据你的实际数据结构进行调整
        }));
        setEvents(eventList);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMoreData = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    const excludedIds = events.map(event => event.id);
    const limit = 5;
    try {
      const res = await getEventsCoverByRandom({ limit, excludedIds });
      if (res && res.data) {
        const moreEvents = res.data.map((event: any) => ({
          id: event.id,
          date: event.date || '', // 这里需要根据你的实际数据结构进行调整
          title: event.title,
          eventDate: event.subtitle || '', // 这里需要根据你的实际数据结构进行调整
          eventDescC: event.description,
          eventDescE: event.en_description,
          manager: "Krueger Smith", // 假设这个字段是固定的
          postedDate: event.date || '' // 这里需要根据你的实际数据结构进行调整
        }));
        setEvents(prevEvents => [...prevEvents, ...moreEvents]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [events, loading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div id={id} className="flex flex-col items-center self-stretch px-20 py-20 mt-32 w-full bg-stone-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <header className="text-xl font-medium text-center text-orange-800">
        活动列表
      </header>
      <h1 className="mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Event List
      </h1>
      <main className="self-stretch mt-18 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        {events.map((event, index) => (
          <div className="self-stretch mt-16 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full" key={index}>
            <Event {...event} />
          </div>
        ))}
      </main>
      <nav className="justify-center items-center px-16 py-0 mt-28 mb-10 max-w-full text-xl font-bold text-white whitespace-nowrap border-fuchsia-700 border-solid border-[3px] leading-[40px] rounded-[51.2px] w-[230px] max-md:px-5 max-md:mt-10">
        <a href="#" onClick={fetchMoreData} className="block w-full h-full">More</a>
      </nav>
    </div>
  );
};

export default EventWall; */

/* import { useState, useEffect, useCallback } from "react";
import { getEventsCoverByRandom } from '../api';

type EventProps = {
  id: number;
  date: string;
  title: string;
  imgSrc: string;
  altText: string;
  eventDate: string;
  eventDescC: string;
  eventDescE: string;
  manager: string;
  postedDate: string;
};

const Event: React.FC<EventProps> = ({
  date,
  title,
  imgSrc,
  altText,
  eventDate,
  eventDescC,
  eventDescE,
  manager,
  postedDate,
}) => (
  <article className="flex gap-5 max-md:flex-col max-md:gap-0">
    <figure className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <img
        loading="lazy"
        src={imgSrc}
        alt={altText}
        className="grow w-full aspect-[1.22] max-md:mt-10 max-md:max-w-full"
      />
    </figure>
    <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
      <h2 className="flex flex-col mt-7 text-4xl font-medium text-orange-100 leading-[56px] max-md:mt-10 max-md:max-w-full">
        <span className="max-md:max-w-full">{title}</span>
        <div className="flex flex-col self-end mt-5 mr-7 max-w-full text-neutral-400 w-[389px] max-md:mr-2.5">
          <img
            loading="lazy"
            src={imgSrc}
            alt={altText}
            className="self-end max-w-full border-fuchsia-600 border-solid aspect-[100] border-[3px] stroke-[3.306px] stroke-fuchsia-600 w-[283px]"
          />
          <figcaption className="mt-6">{eventDate}</figcaption>
        </div>
      </h2>
      <div className="text-left">
        <p className="mt-14 text-base leading-8 text-orange-200 max-md:mt-10 max-md:max-w-full">
          {eventDescC}
        </p>
        <p className="mt-5 text-base font-light leading-8 text-orange-200 max-md:max-w-full">
          {eventDescE}
        </p>
      </div>
      <div className="flex flex-col self-end mt-8 text-base">
        <span>店长: {manager}</span>
        <time className="self-end mt-2.5">{postedDate}</time>
      </div>
    </div>
  </article>
);

const EventWall: React.FC<{ id?: string }> = ({ id }) => {
  const [events, setEvents] = useState<EventProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const limit = 2;
    try {
      const res = await getEventsCoverByRandom({ limit, excludedIds });
      if (res && res.data) {
        const eventList = res.data.map((event: any) => ({
          id: event.id,
          date: event.date || '', // 这里需要根据你的实际数据结构进行调整
          title: event.title,
          imgSrc: event.cover,
          altText: "Event image",
          eventDate: event.date || '', // 这里需要根据你的实际数据结构进行调整
          eventDescC: event.description,
          eventDescE: event.en_description,
          manager: "Krueger Smith", // 假设这个字段是固定的
          postedDate: event.date || '' // 这里需要根据你的实际数据结构进行调整
        }));
        setEvents(eventList);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMoreData = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    const excludedIds = events.map(event => event.id);
    const limit = 5;
    try {
      const res = await getEventsCoverByRandom({ limit, excludedIds });
      if (res && res.data) {
        const moreEvents = res.data.map((event: any) => ({
          id: event.id,
          date: event.date || '', // 这里需要根据你的实际数据结构进行调整
          title: event.title,
          imgSrc: event.cover,
          altText: "Event image",
          eventDate: event.date || '', // 这里需要根据你的实际数据结构进行调整
          eventDescC: event.description,
          eventDescE: event.en_description,
          manager: "Krueger Smith", // 假设这个字段是固定的
          postedDate: event.date || '' // 这里需要根据你的实际数据结构进行调整
        }));
        setEvents(prevEvents => [...prevEvents, ...moreEvents]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [events, loading]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div id={id} className="flex flex-col items-center self-stretch px-20 py-20 mt-32 w-full bg-stone-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <header className="text-xl font-medium text-center text-orange-800">
        活动列表
      </header>
      <h1 className="mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Event List
      </h1>
      <main className="self-stretch mt-18 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full">
        {events.map((event, index) => (
          <div className="self-stretch mt-16 mr-3 ml-3 max-md:mt-10 max-md:mr-2.5 max-md:max-w-full" key={index}>
            <Event {...event} />
          </div>
        ))}
      </main>
      <nav className="justify-center items-center px-16 py-0 mt-28 mb-10 max-w-full text-xl font-bold text-white whitespace-nowrap border-fuchsia-700 border-solid border-[3px] leading-[40px] rounded-[51.2px] w-[230px] max-md:px-5 max-md:mt-10">
        <a href="#" onClick={fetchMoreData} className="block w-full h-full">More</a>
      </nav>
    </div>
  );
};

export default EventWall; */


/* import React, { useState, useEffect, useCallback } from 'react';
import { getEventsCoverByRandom } from '../api'; // 假设你有一个这样的 API 方法
import { useNavigate } from "react-router-dom";

interface EventCover {
  id: number;
  cover: string;
  title: string;
  subtitle?: string;
  description?: string;
  en_description?: string; // 新增字段
}

interface EventCoverCardProps {
  event: EventCover;
  onClick: () => void;
  isMobile: boolean;
}

const EventCoverCard: React.FC<EventCoverCardProps> = ({ event, onClick, isMobile }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);

  return (
    <article className={`flex ${isMobile ? 'flex-col' : 'flex-row'} cursor-pointer rounded-lg overflow-hidden mb-4 w-full p-4`} onClick={onClick}>
      <figure className="flex flex-col w-full sm:w-1/2">
        <div className="aspect-w-16 aspect-h-9 w-full">
          <img
            //className="rounded-lg object-cover w-full h-full"
            className={`rounded-lg object-cover w-full h-full ${isMobile ? 'ml-8 mr-8' : 'ml-12 mr-12'}`}
            src={event?.cover}
            alt={event?.title}
            onLoad={() => setImgLoad(true)}
            onError={() => setImgLoad(false)}
          />
          {imgLoad ? null : <div className='bg-[#d0c9c9] w-full h-full skeleton rounded-lg'></div>}
        </div>
      </figure>
      <div className={`flex flex-col justify-start ${isMobile ? 'ml-8 mr-8' : 'ml-12 mr-12'} text-left w-full sm:w-1/2`}>
        <p className={`text-2xl ${isMobile ? 'text-xl ml-8 mr-8' : 'sm:text-3xl ml-12 mr-12'} text-orange-100`}>{event?.title}</p>
        <p className={`text-lg ${isMobile ? 'text-base ml-8 mr-8' : 'sm:text-xl ml-12 mr-12'} text-orange-100`}>{event?.subtitle || ''}</p>
        <p className={`text-sm ${isMobile ? 'text-xs ml-8 mr-8' : 'sm:text-lg ml-12 mr-12'} text-orange-200`}>{event?.description || ''}</p>
        <p className={`text-sm ${isMobile ? 'text-xs ml-8 mr-8' : 'sm:text-lg ml-12 mr-12'} text-orange-200`}>{event?.en_description || ''}</p>
      </div>
    </article>
  );
};

const EventWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [eventList, setEventList] = useState<EventCover[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const navigate = useNavigate();

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const limit = 20; // 设定一次加载的限制
    getEventsCoverByRandom({ limit, excludedIds }).then((res: any) => {
      const newList = res?.data || [];
      setEventList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // 初始化检查
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (eventList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className="bg-black max-w-[1440px] w-full mx-auto">
      <main className="flex flex-col items-center px-5 py-10">
        <header className="text-xl font-medium text-center text-orange-800">
          活动列表
        </header>
        <h1 className="mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
          Event List
        </h1>
        <div className='media-list grid grid-cols-1 gap-4'>
          {eventList.map((event: EventCover, index: number) => (
            <EventCoverCard
              key={event.id}
              event={event}
              onClick={() => navigate(`/eventsub/${event.id}`)} // 假设你有一个详细的事件页面
              isMobile={isMobile}
            />
          ))}
        </div>
      </main>
    </div>
  );
};

export default EventWall; */


/* import React, { useState, useEffect, useCallback } from 'react';
import { getEventsCoverByRandom } from '../api'; // 假设你有一个这样的 API 方法
import { Image } from 'antd';
import { contact } from '../grocery/utils';

interface EventCover {
  id: number;
  cover: string;
  title: string;
  subtitle?: string;
  description?: string;
  en_description?: string; // 新增字段
}

interface EventCoverCardProps {
  event: EventCover;
  index: number;
}

const EventCoverCard: React.FC<EventCoverCardProps> = ({ event, index }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);

  return (
    <div className="flex flex-col sm:flex-row cursor-pointer rounded-lg overflow-hidden mb-4 w-full p-4">
      <img
        className="rounded-lg contrast-115 object-cover w-full sm:w-1/2 h-[200px] sm:h-[auto]"
        src={event?.cover}
        alt={event?.title}
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
      />
      {imgLoad ? null : <div className='bg-[#d0c9c9] w-full sm:w-1/2 h-[200px] sm:h-[auto] skeleton rounded-lg'></div>}
      <div className="flex flex-col justify-start p-4 text-left w-full sm:w-1/2">
        <p className="text-2xl sm:text-3xl text-orange-100">{event?.title}</p>
        <p className="text-lg sm:text-xl text-orange-100">{event?.subtitle || ''}</p>
        <p className="text-sm sm:text-lg text-orange-200">{event?.description || ''}</p>
        <p className="text-sm sm:text-lg text-orange-200">{event?.en_description || ''}</p>
      </div>
    </div>
  );
};

const EventWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [eventList, setEventList] = useState<any[]>([]);
  const [isMobile, setIsMobile] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const sort = 'rand'; // 默认排序
    const limit = 20; // 设定一次加载的限制
    getEventsCoverByRandom({ orderBy: sort, limit, excludedIds }).then((res: any) => {
      const newList = res?.data || [];
      console.log('Loaded events:', newList); // 添加这一行调试
      setEventList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // 初始化检查
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (eventList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className='explore flex flex-col items-center mt-36'>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className="self-center text-xl font-medium text-center text-orange-800">
        活动列表
      </div>
      <div className="self-center mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Event List
      </div>
      <div className='h-28 lg:h-32'></div>
      <div className='media-list grid grid-cols-1 gap-4'>
        {eventList.map((event: any, index: number) => (
          <EventCoverCard
            index={index}
            key={"event" + event?.id}
            event={event}
          />
        ))}
      </div>
    </div>
  );
};

export default EventWall; */



/* import React, { useState, useEffect, useCallback } from 'react';
import { getEventsCoverByRandom } from '../api'; // 假设你有一个这样的 API 方法
import { Image } from 'antd';
import { contact } from '../grocery/utils';

interface EventCover {
  id: number;
  cover: string;
  title: string;
  subtitle?: string;
  description?: string;
}

interface EventCoverCardProps {
  event: EventCover;
  index: number;
}

const EventCoverCard: React.FC<EventCoverCardProps> = ({ event, index }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);

  return (
    <div className="flex flex-col sm:flex-row cursor-pointer rounded-lg overflow-hidden mb-4 w-full p-4">
      <img
        className="rounded-lg contrast-115 object-cover w-full sm:w-1/2 h-[200px] sm:h-[auto]"
        src={event?.cover}
        alt={event?.title}
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
      />
      {imgLoad ? null : <div className='bg-[#d0c9c9] w-full sm:w-1/2 h-[200px] sm:h-[auto] skeleton rounded-lg'></div>}
      <div className="flex flex-col justify-start p-4 text-left w-full sm:w-1/2">
        <p className="text-2xl sm:text-3xl text-orange-100">{event?.title}</p>
        <p className="text-lg sm:text-xl text-orange-100">{event?.subtitle || ''}</p>
        <p className="text-sm sm:text-lg text-orange-200">{event?.description || ''}</p>
      </div>
    </div>
  );
};

const EventWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [eventList, setEventList] = useState<any[]>([]);
  const [isMobile, setIsMobile] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const sort = 'rand'; // 默认排序
    const limit = 20; // 设定一次加载的限制
    getEventsCoverByRandom({ orderBy: sort, limit, excludedIds }).then((res: any) => {
      const newList = res?.data || [];
      setEventList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // 初始化检查
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (eventList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className='explore flex flex-col items-center mt-36'>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className="self-center text-xl font-medium text-center text-orange-800">
        活动列表
      </div>
      <div className="self-center mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Event List
      </div>
      <div className='h-28 lg:h-32'></div>
      <div className='media-list grid grid-cols-1 gap-4'>
        {eventList.map((event: any, index: number) => (
          <EventCoverCard
            index={index}
            key={"event" + event?.id}
            event={event}
          />
        ))}
      </div>
    </div>
  );
};

export default EventWall;
 */
