import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const quickLinks = [
    { key: "aboutUs", mainText: "About Us", subText: "关于我们", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "aboutGirls", mainText: "About Girls", subText: "日本女公关", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "eventsNews", mainText: "Events News", subText: "最新信息", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "recruit", mainText: "Recruit", subText: "求人情報", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "storeLocation", mainText: "Store Location", subText: "店舗位置", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  ];

  const handleClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const navigate = useNavigate();

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="container mx-auto bg-black bg-opacity-50 backdrop-blur-lg p-4 fixed top-0 w-full z-50 rounded-bl-[40px] rounded-br-[40px] max-w-[1440px] mx-auto">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {isMobile && (
            <button onClick={toggleMenu} className="text-white mr-2">
              <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/hamburger_menu.png" alt="Menu Icon" className="h-4 w-4"/>
            </button>
          )}
          <div className={`text-white font-bold ${isMobile ? 'text-2xl' : 'text-3xl'}`}>
            <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/header_logo.png" alt="Logo" className={`inline-block ${isMobile ? 'h-[25px]' : 'h-[35px]'}`} onClick={() => navigate('/')}/>
          </div>
        </div>
        {!isMobile && (
          <nav className="flex space-x-8 text-white">
            {quickLinks.map((link) => (
              <div key={link.key} className="text-center group">
                <div onClick={() => handleClick(link.key)} className={`${link.mainTextClass} cursor-pointer`}>{link.mainText}</div>
                <div onClick={() => handleClick(link.key)} className={`${link.subTextClass} font-bold relative cursor-pointer`}>
                  {link.subText}
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-white opacity-50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </div>
              </div>
            ))}
          </nav>
        )}
        <a href="tel:03-4362-8777" className={`bg-transparent border border-pink-600 text-white rounded-full flex items-center space-x-2 ${isMobile ? 'px-3 py-1 text-sm' : 'px-6 py-2'}`}>
          <span>即刻预定</span>
          <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phone_call.png" alt="Phone" className={`w-5 h-5 ${isMobile ? 'w-4 h-4' : 'w-5 h-5'}`} />
        </a>
      </div>
      {isMobile && isMenuOpen && (
        <div className="fixed top-[calc(100%+10px)] left-0 w-[60%] max-w-xs h-full bg-opacity-80 text-white z-40 p-4">
          <nav className="flex flex-col space-y-4">
            {quickLinks.map((link) => (
              <div key={link.key} className="text-center group">
                <div onClick={() => handleClick(link.key)} className={`cursor-pointer ${isMobile ? 'text-sm text-customGold' : link.mainTextClass}`}>{link.mainText}</div>
                <div onClick={() => handleClick(link.key)} className={`font-bold relative cursor-pointer ${isMobile ? 'text-sm text-customGold' : link.subTextClass}`}>
                  {link.subText}
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-white opacity-50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </div>
              </div>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;

/* import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const quickLinks = [
    { key: "aboutGirls", mainText: "About Girls", subText: "关于Girls", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "aboutUs", mainText: "About Us", subText: "关于我们", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "eventsNews", mainText: "Events News", subText: "最新信息", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "recruit", mainText: "Recruit", subText: "求人情報", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "storeLocation", mainText: "Store Location", subText: "店舗位置", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  ];

  const handleClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const navigate = useNavigate();

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="container mx-auto bg-black bg-opacity-50 backdrop-blur-lg p-4 fixed top-0 w-full z-50 rounded-bl-[40px] rounded-br-[40px] max-w-[1440px] mx-auto">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {isMobile && (
            <button onClick={toggleMenu} className="text-white mr-2">
              <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/hamburger_menu.png" alt="Menu Icon" className="h-4 w-4"/>
            </button>
          )}
          <div className={`text-white font-bold ${isMobile ? 'text-2xl' : 'text-3xl'}`}>
            <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/header_logo.png" alt="Logo" className={`inline-block ${isMobile ? 'h-[25px]' : 'h-[35px]'}`} onClick={() => navigate('/')}/>
          </div>
        </div>
        {!isMobile && (
          <nav className="flex space-x-8 text-white">
            {quickLinks.map((link) => (
              <div key={link.key} className="text-center group">
                <div onClick={() => handleClick(link.key)} className={`${link.mainTextClass} cursor-pointer`}>{link.mainText}</div>
                <div onClick={() => handleClick(link.key)} className={`${link.subTextClass} font-bold relative cursor-pointer`}>
                  {link.subText}
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-white opacity-50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </div>
              </div>
            ))}
          </nav>
        )}
        <a href="tel:03-4362-8777" className={`bg-transparent border border-pink-600 text-white rounded-full flex items-center space-x-2 ${isMobile ? 'px-3 py-1 text-sm' : 'px-6 py-2'}`}>
          <span>即刻预定</span>
          <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phone_call.png" alt="Phone" className={`w-5 h-5 ${isMobile ? 'w-4 h-4' : 'w-5 h-5'}`} />
        </a>
      </div>
      {isMobile && isMenuOpen && (
        <div className="fixed top-[calc(100%+10px)] left-0 w-[40%] max-w-xs h-full bg-opacity-80 text-white z-40 p-4">
          <nav className="flex flex-col space-y-4">
            {quickLinks.map((link) => (
              <div key={link.key} className="text-center group">
                <div onClick={() => handleClick(link.key)} className={`cursor-pointer ${isMobile ? 'text-sm text-customGold' : link.mainTextClass}`}>{link.mainText}</div>
                <div onClick={() => handleClick(link.key)} className={`font-bold relative cursor-pointer ${isMobile ? 'text-sm text-customGold' : link.subTextClass}`}>
                  {link.subText}
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-white opacity-50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </div>
              </div>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header; */

/* import React, { useState, useRef, useEffect } from 'react';

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const quickLinks = [
    { key: "aboutGirls", mainText: "About Girls", subText: "关于Girls", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "aboutUs", mainText: "About Us", subText: "关于我们", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "eventsNews", mainText: "Events News", subText: "最新信息", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "recruit", mainText: "Recruit", subText: "求人情報", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "storeLocation", mainText: "Store Location", subText: "店舗位置", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  ];

  const handleClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="container mx-auto bg-black bg-opacity-50 backdrop-blur-lg p-4 fixed top-0 w-full z-50 rounded-bl-[40px] rounded-br-[40px] max-w-[1440px] mx-auto">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {isMobile && (
            <button onClick={toggleMenu} className="text-white mr-2">
              <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/hamburger_menu.png" alt="Menu Icon" className="h-4 w-4"/>
            </button>
          )}
          <div className={`text-white font-bold ${isMobile ? 'text-2xl' : 'text-3xl'}`}>
            <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/header_logo.png" alt="Logo" className={`inline-block ${isMobile ? 'h-[25px]' : 'h-[35px]'}`} />
          </div>
        </div>
        {!isMobile && (
          <nav className="flex space-x-8 text-white">
            {quickLinks.map((link) => (
              <div key={link.key} className="text-center group">
                <div onClick={() => handleClick(link.key)} className={`${link.mainTextClass} cursor-pointer`}>{link.mainText}</div>
                <div onClick={() => handleClick(link.key)} className={`${link.subTextClass} font-bold relative cursor-pointer`}>
                  {link.subText}
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-white opacity-50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </div>
              </div>
            ))}
          </nav>
        )}
        <button ref={buttonRef} onClick={toggleModal} className={`bg-transparent border border-pink-600 text-white rounded-full flex items-center space-x-2 ${isMobile ? 'px-3 py-1 text-sm' : 'px-6 py-2'}`}>
          <span>即刻预定</span>
          <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phone_call.png" alt="Phone" className={`w-5 h-5 ${isMobile ? 'w-4 h-4' : 'w-5 h-5'}`} />
        </button>
      </div>
      {isModalOpen && (
        <div
          className={`fixed z-50 flex flex-col justify-center px-10 py-20 backdrop-blur-[20px] bg-purple-900 bg-opacity-20 rounded-[30px] mr-4 ${isMobile ? 'max-w-[50%] w-[50%]' : 'max-w-[541px] w-[541px]'}`}
          style={{ top: 'calc(100% + 10px)', right: '1rem' }}
          onClick={toggleModal}
        >
          <section className={`shrink-0 h-20 backdrop-blur-[20px] bg-white bg-opacity-10 rounded-[30px] flex items-center justify-center ${isMobile ? 'text-sm' : 'text-xl'}`}>
            <p className="text-white font-bold">電話TEL 03-4362-8777</p>
          </section>
        </div>
      )}
      {isMobile && isMenuOpen && (
        <div className="fixed top-[calc(100%+10px)] left-0 w-[40%] max-w-xs h-full bg-opacity-80 text-white z-40 p-4">
          <nav className="flex flex-col space-y-4">
            {quickLinks.map((link) => (
              <div key={link.key} className="text-center group">
                <div onClick={() => handleClick(link.key)} className={`cursor-pointer ${isMobile ? 'text-sm text-customGold' : link.mainTextClass}`}>{link.mainText}</div>
                <div onClick={() => handleClick(link.key)} className={`font-bold relative cursor-pointer ${isMobile ? 'text-sm text-customGold' : link.subTextClass}`}>
                  {link.subText}
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-white opacity-50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </div>
              </div>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
 */
/* import React, { useState, useRef, useEffect } from 'react';

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const quickLinks = [
    { key: "aboutGirls", mainText: "About Girls", subText: "关于Girls", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "aboutUs", mainText: "About Us", subText: "关于我们", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "eventsNews", mainText: "Events News", subText: "最新信息", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "recruit", mainText: "Recruit", subText: "求人情報", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
    { key: "storeLocation", mainText: "Store Location", subText: "店舗位置", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  ];

  const handleClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <header className="container mx-auto bg-black bg-opacity-50 backdrop-blur-lg p-4 fixed top-0 w-full z-50 rounded-bl-[40px] rounded-br-[40px] max-w-[1440px] mx-auto">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {isMobile && (
            <button onClick={toggleMenu} className="text-white mr-2">
              菜单
            </button>
          )}
          <div className={`text-white font-bold ${isMobile ? 'text-2xl' : 'text-3xl'}`}>
            <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/header_logo.png" alt="Logo" className={`inline-block ${isMobile ? 'h-[25px]' : 'h-[35px]'}`} />
          </div>
        </div>
        {!isMobile && (
          <nav className="flex space-x-8 text-white">
            {quickLinks.map((link) => (
              <div key={link.key} className="text-center group">
                <div onClick={() => handleClick(link.key)} className={`${link.mainTextClass} cursor-pointer`}>{link.mainText}</div>
                <div onClick={() => handleClick(link.key)} className={`${link.subTextClass} font-bold relative cursor-pointer`}>
                  {link.subText}
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-white opacity-50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </div>
              </div>
            ))}
          </nav>
        )}
        <button ref={buttonRef} onClick={toggleModal} className={`bg-transparent border border-pink-600 text-white rounded-full flex items-center space-x-2 ${isMobile ? 'px-3 py-1 text-sm' : 'px-6 py-2'}`}>
          <span>即刻预定</span>
          <img src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phone_call.png" alt="Phone" className={`w-5 h-5 ${isMobile ? 'w-4 h-4' : 'w-5 h-5'}`} />
        </button>
      </div>
      {isModalOpen && (
        <div
          className={`fixed z-50 flex flex-col justify-center px-10 py-20 backdrop-blur-[20px] bg-purple-900 bg-opacity-20 rounded-[30px] mr-4 ${isMobile ? 'max-w-[50%] w-[50%]' : 'max-w-[541px] w-[541px]'}`}
          style={{ top: 'calc(100% + 10px)', right: '1rem' }}
          onClick={toggleModal}
        >
          <section className={`shrink-0 h-20 backdrop-blur-[20px] bg-white bg-opacity-10 rounded-[30px] flex items-center justify-center ${isMobile ? 'text-sm' : 'text-xl'}`}>
            <p className="text-white font-bold">電話TEL  03-4361-9972</p>
          </section>
        </div>
      )}
      {isMobile && isMenuOpen && (
        <div className="fixed top-[calc(100%+10px)] left-0 w-[40%] max-w-xs h-full bg-opacity-80 text-white z-40 p-4">
          <nav className="flex flex-col space-y-4">
            {quickLinks.map((link) => (
              <div key={link.key} className="text-center group">
                <div onClick={() => handleClick(link.key)} className={`cursor-pointer ${isMobile ? 'text-sm text-customGold' : link.mainTextClass}`}>{link.mainText}</div>
                <div onClick={() => handleClick(link.key)} className={`font-bold relative cursor-pointer ${isMobile ? 'text-sm text-customGold' : link.subTextClass}`}>
                  {link.subText}
                  <div className="absolute left-0 bottom-0 w-full h-0.5 bg-white opacity-50 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                </div>
              </div>
            ))}
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
 */