import React, { useState, useEffect } from 'react';

type QuickLink = {
  key: string;
  mainText: string;
  subText: string;
  mainTextClass?: string;
  subTextClass?: string;
};

type Address = {
  line1: string;
  line2: string;
};

const quickLinks: QuickLink[] = [
  { key: "aboutUs", mainText: "About US", subText: "关于我们", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  { key: "aboutGirls", mainText: "About Girls", subText: "关于女公关", mainTextClass: "text-customGold text-sm mt-3", subTextClass: "text-customGold text-md" },
  { key: "eventsNews", mainText: "Events News", subText: "最新信息", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  { key: "recruit", mainText: "Recruit", subText: "求人情報", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
];

const address: Address = {
  line1: "東京都中央区銀座8丁目3番11号 和恒ビル3階B部分",
  line2: "WAKO BLD., 3F, 8-chōme-3-11 Ginza, Chuo City, Tokyo 104-0061",
};

const Footer: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleReservationClick = () => {
    window.location.href = "https://forms.gle/9uZrBhn4s3CCek9W9";
  };

  return (
    <footer className="w-full bg-zinc-900 max-md:max-w-full">
      <div className={`self-stretch px-16 pt-20 pb-4 mt-40 w-full ${isMobile ? 'px-5 mt-10' : ''}`}>
        <div className={`flex ${isMobile ? 'flex-col gap-0' : 'gap-5'}`}>
          <section className={`flex flex-col ${isMobile ? 'w-full' : 'w-[65%] ml-0'}`}>
            <article className={`flex flex-col grow ${isMobile ? 'mt-10' : ''}`}>
              <div className={`flex ${isMobile ? 'flex-wrap max-w-full' : 'gap-5 justify-between'}`}>
                <div className={`flex flex-col ${isMobile ? 'items-center w-full' : ''}`}>
                  <img
                    loading="lazy"
                    src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phonefooterlogo.png"
                    className={`max-w-full w-[164px] h-auto ${isMobile ? 'ml-0' : 'ml-12'}`}
                    style={{ objectFit: 'contain' }}  // 确保图像保持原有比例
                    alt="A luxurious venue with beautiful hostesses"
                  />
                  <h2 className={`mt-7 text-xl font-medium leading-8 text-white ${isMobile ? 'text-center ml-0' : 'text-left ml-12'}`}>
                    奢华之境,佳人相伴,醉心日本,一切尽在银座<br />Shihoo 紫峰高级俱乐部
                  </h2>
                  {!isMobile && (
                    <div className={`flex gap-2 justify-between py-2 pr-2 pl-2 mt-8 bg-fuchsia-900 backdrop-blur-[7.40625px] rounded-[40px_40px_40px_0px] max-w-md mx-auto ${isMobile ? 'px-3 mt-6 ml-0' : 'ml-8'}`}>
                      <div className="flex flex-col text-white whitespace-nowrap" onClick={handleReservationClick}>
                        <span className="text-md leading-6 mr-14 ml-14">Reservation</span>
                        <h3 className="mt-1 text-md font-bold text-center leading-6 mr-14 ml-14">即刻預定</h3>
                      </div>
                      <div className="flex flex-col justify-center items-start my-auto">
                        <img
                          loading="lazy"
                          src={isMobile ? "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phonefooterlogo.png" : "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/arrow_icon.png"}
                          className="bg-fuchsia-700 rounded-full w-[45px] h-auto"
                          alt="Reservation icon"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <nav className={`flex flex-col self-start py-0.5 mt-4 text-xs font-medium leading-6 text-orange-100 ${isMobile ? 'items-center w-full' : ''}`}>
                  {!isMobile && <h3 className="text-2xl leading-10 text-white">Quicklinks</h3>}
                  {quickLinks.map((link) => (
                    <div key={link.key} className="mt-5 max-md:mr-1 flex flex-col">
                      <a href={`#${link.key}`} onClick={() => handleClick(link.key)} className={`text-lg ${link.mainTextClass} ${isMobile ? 'text-base text-purple-400' : ''}`}>
                        {link.mainText}
                      </a>
                      <span onClick={() => handleClick(link.key)} className={`mt-1.5 text-lg font-bold leading-6 max-md:mr-1 ${link.subTextClass} ${isMobile ? 'text-base' : ''}`}>
                        {link.subText}
                      </span>
                    </div>
                  ))}
                </nav>
              </div>
            </article>
          </section>
          <aside className={`flex flex-col text-left mt-0 ${isMobile ? 'ml-0 w-full items-center mt-5' : 'ml-5 w-[35%]'}`}>
            <section className={`flex flex-col mt-5 ${isMobile ? 'mt-10 items-center w-full' : ''}`}>
              {!isMobile && <h2 className="text-2xl leading-10 text-white">聯繫我們</h2>}
              <div className={`flex gap-2.5 mt-7 ${isMobile ? 'flex-col items-center w-full' : ''}`}>
                <div className="flex flex-col self-start">
                  <div className="shrink-0 bg-fuchsia-600 rounded-full h-[11px]" />
                  <div className="shrink-0 mt-12 bg-fuchsia-600 rounded-full h-[11px] max-md:mt-10" />
                </div>
                <address className={`flex flex-col grow shrink-0 text-lg leading-8 text-white basis-0 w-fit not-italic ${isMobile ? 'text-center text-sm mt-4' : ''}`}>
                  <p className={`mt-0 ${isMobile ? 'w-full text-sm' : 'text-md'}`}>{address.line1}</p>
                  <p className={`mt-4 text-md ${isMobile ? 'w-full text-sm' : 'text-md'}`}>{address.line2}</p> {/* 修改的部分 */}
                </address>
              </div>
              <p className={`font-light leading-10 text-white ${isMobile ? 'text-center mt-0 w-full text-sm' : 'text-xl mt-7 '}`}>
                Business Cooperation: <a href="mailto:business@shihoo.jp" className="text-purple-400">business@shihoo.jp</a>
              </p>
            </section>
          </aside>
        </div>
      </div>
      <div className="border-t border-white w-full mt-5"></div>
      <div className="flex justify-center mt-2 text-xs leading-6 text-white max-md:mt-2.5">
        Copyright © 2024 Shihoo Project
      </div>
    </footer>
  );
};

export default Footer;
/* import React, { useState, useEffect } from 'react';

type QuickLink = {
  key: string;
  mainText: string;
  subText: string;
  mainTextClass?: string;
  subTextClass?: string;
};

type Address = {
  line1: string;
  line2: string;
};

const quickLinks: QuickLink[] = [
  { key: "aboutGirls", mainText: "About Girls", subText: "关于Girls", mainTextClass: "text-customGold text-sm mt-3", subTextClass: "text-customGold text-md" },
  { key: "aboutUs", mainText: "About US", subText: "关于我们", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  { key: "eventsNews", mainText: "Events News", subText: "最新信息", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  { key: "recruit", mainText: "Recruit", subText: "求人情報", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
];

const address: Address = {
  line1: "東京都中央区銀座8丁目3番11号 和恒ビル3階B部分",
  line2: "WAKO BLD., 3F, 8-chōme-3-11 Ginza, Chuo City, Tokyo 104-0061",
};

const Footer: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleReservationClick = () => {
    window.location.href = "https://forms.gle/9uZrBhn4s3CCek9W9";
  };

  return (
    <footer className="w-full bg-zinc-900 max-md:max-w-full">
      <div className={`self-stretch px-16 pt-20 pb-4 mt-40 w-full ${isMobile ? 'px-5 mt-10' : ''}`}>
        <div className={`flex ${isMobile ? 'flex-col gap-0' : 'gap-5'}`}>
          <section className={`flex flex-col ${isMobile ? 'w-full' : 'w-[65%] ml-0'}`}>
            <article className={`flex flex-col grow ${isMobile ? 'mt-10' : ''}`}>
              <div className={`flex ${isMobile ? 'flex-wrap max-w-full' : 'gap-5 justify-between'}`}>
                <div className={`flex flex-col ${isMobile ? 'items-center w-full' : ''}`}>
                  <img
                    loading="lazy"
                    src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phonefooterlogo.png"
                    className={`max-w-full aspect-[1.67] w-[164px] ${isMobile ? 'ml-0' : 'ml-12'}`}
                    alt="A luxurious venue with beautiful hostesses"
                  />
                  <h2 className={`mt-7 text-xl font-medium leading-8 text-white ${isMobile ? 'text-center ml-0' : 'text-left ml-12'}`}>
                    奢华之境,佳人相伴,醉心日本,一切尽在银座<br />Shihoo 紫峰高级俱乐部
                  </h2>
                  {!isMobile && (
                    <div className={`flex gap-2 justify-between py-2 pr-2 pl-2 mt-8 bg-fuchsia-900 backdrop-blur-[7.40625px] rounded-[40px_40px_40px_0px] max-w-md mx-auto ${isMobile ? 'px-3 mt-6 ml-0' : 'ml-8'}`}>
                      <div className="flex flex-col text-white whitespace-nowrap" onClick={handleReservationClick}>
                        <span className="text-md leading-6 mr-14 ml-14">Reservation</span>
                        <h3 className="mt-1 text-md font-bold text-center leading-6 mr-14 ml-14">即刻預定</h3>
                      </div>
                      <div className="flex flex-col justify-center items-start my-auto">
                        <img
                          loading="lazy"
                          src={isMobile ? "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phonefooterlogo.png" : "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/arrow_icon.png"}
                          className="bg-fuchsia-700 rounded-full w-[45px] h-auto"
                          alt="Reservation icon"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <nav className={`flex flex-col self-start py-0.5 mt-4 text-xs font-medium leading-6 text-orange-100 ${isMobile ? 'items-center w-full' : ''}`}>
                  {!isMobile && <h3 className="text-2xl leading-10 text-white">Quicklinks</h3>}
                  {quickLinks.map((link) => (
                    <div key={link.key} className="mt-5 max-md:mr-1 flex flex-col">
                      <a href={`#${link.key}`} onClick={() => handleClick(link.key)} className={`text-lg ${link.mainTextClass} ${isMobile ? 'text-base text-purple-400' : ''}`}>
                        {link.mainText}
                      </a>
                      <span onClick={() => handleClick(link.key)} className={`mt-1.5 text-lg font-bold leading-6 max-md:mr-1 ${link.subTextClass} ${isMobile ? 'text-base' : ''}`}>
                        {link.subText}
                      </span>
                    </div>
                  ))}
                </nav>
              </div>
            </article>
          </section>
          <aside className={`flex flex-col text-left mt-0 ${isMobile ? 'ml-0 w-full items-center mt-5' : 'ml-5 w-[35%]'}`}>
            <section className={`flex flex-col mt-5 ${isMobile ? 'mt-10 items-center w-full' : ''}`}>
              {!isMobile && <h2 className="text-2xl leading-10 text-white">聯繫我們</h2>}
              <div className={`flex gap-2.5 mt-7 ${isMobile ? 'flex-col items-center w-full' : ''}`}>
                <div className="flex flex-col self-start">
                  <div className="shrink-0 bg-fuchsia-600 rounded-full h-[11px]" />
                  <div className="shrink-0 mt-12 bg-fuchsia-600 rounded-full h-[11px] max-md:mt-10" />
                </div>
                <address className={`flex flex-col grow shrink-0 text-lg leading-8 text-white basis-0 w-fit not-italic ${isMobile ? 'text-center text-sm mt-4' : ''}`}>
                  <p className={`mt-0 ${isMobile ? 'w-full text-sm' : 'text-md'}`}>{address.line1}</p>
                  <p className={`mt-4 text-md ${isMobile ? 'w-full text-sm' : 'text-md'}`}>{address.line2}</p> 
                </address>
              </div>
              <p className={`font-light leading-10 text-white ${isMobile ? 'text-center mt-0 w-full text-sm' : 'text-xl mt-7 '}`}>
                Business Cooperation: <a href="mailto:business@shihoo.jp" className="text-purple-400">business@shihoo.jp</a>
              </p>
            </section>
          </aside>
        </div>
      </div>
      <div className="border-t border-white w-full mt-5"></div>
      <div className="flex justify-center mt-2 text-xs leading-6 text-white max-md:mt-2.5">
        Copyright © 2024 Shihoo Project
      </div>
    </footer>
  );
};

export default Footer;
 */


/* import React, { useState, useRef, useEffect } from 'react';


type QuickLink = {
  key: string;
  mainText: string;
  subText: string;
  mainTextClass?: string;
  subTextClass?: string;
};

type Address = {
  line1: string;
  line2: string;
};

const quickLinks: QuickLink[] = [
  { key: "aboutGirls", mainText: "About Girls", subText: "关于Girls", mainTextClass: "text-customGold text-sm mt-3", subTextClass: "text-customGold text-md" },
  { key: "aboutUs", mainText: "About US", subText: "关于我们", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  { key: "eventsNews", mainText: "Events News", subText: "最新信息", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
  { key: "recruit", mainText: "Recruit", subText: "求人情報", mainTextClass: "text-customGold text-sm", subTextClass: "text-customGold text-md" },
];

const address: Address = {
  line1: "東京都中央区銀座8丁目3番11号 和恒ビル3階B部分",
  line2: "WAKO BLD., 3F, 8-chōme-3-11 Ginza, Chuo City, Tokyo 104-0061",
};

const Footer: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (key: string) => {
    const element = document.getElementById(key);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleReservationClick = () => {
    window.location.href = "https://forms.gle/9uZrBhn4s3CCek9W9";
  };

  return (
    <footer className="w-full bg-zinc-900 max-md:max-w-full">
      <div className={`self-stretch px-16 pt-20 pb-4 mt-40 w-full ${isMobile ? 'px-5 mt-10' : ''}`}>
        <div className={`flex ${isMobile ? 'flex-col gap-0' : 'gap-5'}`}>
          <section className={`flex flex-col ${isMobile ? 'w-full' : 'w-[65%] ml-0'}`}>
            <article className={`flex flex-col grow ${isMobile ? 'mt-10' : ''}`}>
              <div className={`flex ${isMobile ? 'flex-wrap max-w-full' : 'gap-5 justify-between'}`}>
                <div className={`flex flex-col ${isMobile ? 'items-center w-full' : ''}`}>
                  <img
                    loading="lazy"
                    src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/footer_logo.png"
                    className={`max-w-full aspect-[1.67] w-[164px] ${isMobile ? 'ml-0' : 'ml-12'}`}
                    alt="A luxurious venue with beautiful hostesses"
                  />
                  <h2 className={`mt-7 text-xl font-medium leading-8 text-white ${isMobile ? 'text-center ml-0' : 'text-left ml-12'}`}>
                    奢华之境,佳人相伴,醉心日本,一切尽在银座<br/>Shihoo 紫峰高级俱乐部
                  </h2>
                  {!isMobile && (
                    <div className={`flex gap-2 justify-between py-2 pr-2 pl-2 mt-8 bg-fuchsia-900 backdrop-blur-[7.40625px] rounded-[40px_40px_40px_0px] max-w-md mx-auto ${isMobile ? 'px-3 mt-6 ml-0' : 'ml-8'}`}>
                      <div className="flex flex-col text-white whitespace-nowrap" onClick={handleReservationClick}>
                        <span className="text-md leading-6 mr-14 ml-14">Reservation</span>
                        <h3 className="mt-1 text-md font-bold text-center leading-6 mr-14 ml-14">即刻預定</h3>
                      </div>
                      <div className="flex flex-col justify-center items-start my-auto">
                        <img
                          loading="lazy"
                          src={isMobile ? "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/phonefooterlogo.png" :"https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/arrow_icon.png"}
                          className="bg-fuchsia-700 rounded-full w-[45px] h-auto"
                          alt="Reservation icon"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <nav className={`flex flex-col self-start py-0.5 mt-4 text-xs font-medium leading-6 text-orange-100 ${isMobile ? 'items-center w-full' : ''}`}>
                  {!isMobile && <h3 className="text-2xl leading-10 text-white">Quicklinks</h3>}
                  {quickLinks.map((link) => (
                    <div key={link.key} className="mt-5 max-md:mr-1 flex flex-col">
                      <a href={`#${link.key}`} onClick={() => handleClick(link.key)} className={`text-lg ${link.mainTextClass} ${isMobile ? 'text-base' : ''}`}>
                        {link.mainText}
                      </a>
                      <span onClick={() => handleClick(link.key)} className={`mt-1.5 text-lg font-bold leading-6 max-md:mr-1 ${link.subTextClass} ${isMobile ? 'text-base' : ''}`}>
                        {link.subText}
                      </span>
                    </div>
                  ))}
                </nav>
              </div>
            </article>
          </section>
          <aside className={`flex flex-col text-left mt-0 ${isMobile ? 'ml-0 w-full items-center mt-5' : 'ml-5 w-[35%]'}`}>
            <section className={`flex flex-col mt-5 ${isMobile ? 'mt-10 items-center w-full' : ''}`}>
              {!isMobile && <h2 className="text-2xl leading-10 text-white">聯繫我們</h2>}
              <div className={`flex gap-2.5 mt-7 ${isMobile ? 'flex-col items-center w-full' : ''}`}>
                <div className="flex flex-col self-start">
                  <div className="shrink-0 bg-fuchsia-600 rounded-full h-[11px]" />
                  <div className="shrink-0 mt-12 bg-fuchsia-600 rounded-full h-[11px] max-md:mt-10" />
                </div>
                <address className={`flex flex-col grow shrink-0 text-lg leading-8 text-white basis-0 w-fit not-italic ${isMobile ? 'text-center text-sm mt-4' : ''}`}>
                  <p className={`mt-0 ${isMobile ? 'w-full text-sm' : 'text-md'}`}>{address.line1}</p>
                  {!isMobile && (
                    <p className="mt-4 text-md">{address.line2}</p>
                  )}
                </address>
              </div>
              <p className={`font-light leading-10 text-white ${isMobile ? 'text-center mt-0 w-full text-sm' : 'text-xl mt-7 '}`}>
                Business Cooperation: <a href="mailto:business@shihoo.jp" className="text-purple-400">business@shihoo.jp</a>
              </p>
            </section>
          </aside>
        </div>
      </div>
      <div className="border-t border-white w-full mt-5"></div>
      <div className="flex justify-center mt-2 text-xs leading-6 text-white max-md:mt-2.5">
        Copyright © 2024 Shihoo Project
      </div>
    </footer>
  );
};

export default Footer; */