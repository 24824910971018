// Arrow.tsx
import React, { CSSProperties } from 'react';

interface ArrowProps {
  className?: string;
  style?: CSSProperties; // 新增的 style 属性
}

const Arrow: React.FC<ArrowProps> = ({ className, style }) => {
  return (
    <>
      <style>
        {`
          @keyframes arrow-movement {
            0% { 
              opacity: 0;
              top: 75%;
            }
            70% {
              opacity: 1;
            }
            100% { 
              opacity: 0;
            }
          }
          .arrow {
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform-origin: 50% 50%;
            transform: translate3d(-50%, -50%);
          }
          .arrow-first {
            animation: arrow-movement 2s ease-in-out infinite;
          }
          .arrow-second {
            animation: arrow-movement 2s 1s ease-in-out infinite;
          }
          .arrow:before,
          .arrow:after {
            background: #fff;
            content: '';
            display: block;
            height: 3px;
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
          }
          .arrow:before {
            transform: rotate(45deg) translateX(-23%);
            transform-origin: top left;
          }
          .arrow:after {
            transform: rotate(-45deg) translateX(23%);
            transform-origin: top right;
          }
        `}
      </style>
      <div className={`arrow arrow-first ${className}`} style={style}></div>
      <div className={`arrow arrow-second ${className}`} style={style}></div>
    </>
  );
};

export default Arrow;


