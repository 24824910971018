import React, { useState, useEffect, CSSProperties } from "react";
import Arrow from './Arrow'; // 确保路径正确

interface BannerProps {
  videoSrcMobile: string;
  videoSrcDesktop: string;
  altText: string;
  heading: React.ReactNode;
  className?: string;
  overlayImgSrc?: string;
  overlayClassName?: string;
  headingClassName?: string;
  additionalContent?: React.ReactNode;
  button?: React.ReactNode;
  videoClassName?: string;
  buttonPositionMobile?: CSSProperties;
  buttonPositionDesktop?: CSSProperties;
  headingPositionMobile?: CSSProperties;
  headingPositionDesktop?: CSSProperties;
  overlayPositionMobile?: CSSProperties;
  overlayPositionDesktop?: CSSProperties;
  addtionalImgSrc?: string;
  arrowPositionMobile?: CSSProperties; // 新增的箭头位置
  showArrow?: boolean; // 新增的 prop
  maintainAspectRatio?: boolean; // 新增的 prop
  containerHeight?: string; // 新增的容器高度属性
}

const Banner: React.FC<BannerProps> = ({
  videoSrcMobile,
  videoSrcDesktop,
  altText,
  heading,
  className,
  overlayImgSrc,
  overlayClassName,
  headingClassName,
  additionalContent,
  videoClassName,
  button,
  buttonPositionMobile,
  buttonPositionDesktop,
  headingPositionMobile,
  headingPositionDesktop,
  overlayPositionMobile,
  overlayPositionDesktop,
  addtionalImgSrc,
  arrowPositionMobile, // 新增的箭头位置
  showArrow = true, // 新增的 prop，默认为 true
  maintainAspectRatio = false, // 新增的 prop，默认为 false
  containerHeight = '100vh' // 默认容器高度
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isGif = (src: string) => src.toLowerCase().endsWith('.gif');

  const videoStyles: CSSProperties = isMobile && maintainAspectRatio 
    ? { width: '100%', height: '100%', objectFit: 'cover' }
    : { width: '100%', height: '100%' };

  const containerStyles: CSSProperties = isMobile && maintainAspectRatio 
    ? { position: 'relative', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }
    : { height: containerHeight };

  return (
    <section className={`relative flex flex-col justify-center items-center w-full overflow-hidden ${className}`} style={containerStyles}>
      {isMobile ? (
        isGif(videoSrcMobile) ? (
          <img
            src={videoSrcMobile}
            alt={altText}
            className={`${videoClassName}`}
            style={videoStyles}
          />
        ) : (
          <video
            src={videoSrcMobile}
            className={`${videoClassName}`}
            autoPlay
            loop
            muted
            playsInline
            style={videoStyles}
          >
            <track kind="captions" srcLang="en" label={altText} />
          </video>
        )
      ) : (
        <video
          src={videoSrcDesktop}
          className={`${videoClassName}`}
          autoPlay
          loop
          muted
          playsInline
          style={videoStyles}
        >
          <track kind="captions" srcLang="en" label={altText} />
        </video>
      )}
      {overlayImgSrc && (
        <img
          src={overlayImgSrc}
          alt="Overlay"
          className={`absolute z-10 ${overlayClassName}`}
          style={isMobile ? overlayPositionMobile : overlayPositionDesktop}
        />
      )}
      <div
        className={`${headingClassName}`}
        style={isMobile ? headingPositionMobile : headingPositionDesktop}
      >
        {heading}
      </div>
      <div
        className={`absolute z-20`}
        style={isMobile ? buttonPositionMobile : buttonPositionDesktop}
      >
        {button}
      </div>
      {isMobile && showArrow && <Arrow className="z-20" style={arrowPositionMobile} />}
      {addtionalImgSrc && (
        <img
          src={addtionalImgSrc}
          alt=""
          className={`absolute z-0`}
          style={{ bottom: '-0.5rem', width: '100%' }}
        />
      )}
      {additionalContent && (
        <div className="relative z-20 w-full text-center mt-10" style={{ marginTop: '2.5rem' }}>
          {additionalContent}
        </div>
      )}
    </section>
  );
};

export default Banner;

/* import React, { useState, useEffect, CSSProperties } from "react";
import Arrow from './Arrow'; // 确保路径正确

interface BannerProps {
  videoSrcMobile: string;
  videoSrcDesktop: string;
  altText: string;
  heading: React.ReactNode;
  className?: string;
  overlayImgSrc?: string;
  overlayClassName?: string;
  headingClassName?: string;
  additionalContent?: React.ReactNode;
  button?: React.ReactNode;
  videoClassName?: string;
  buttonPositionMobile?: CSSProperties;
  buttonPositionDesktop?: CSSProperties;
  headingPositionMobile?: CSSProperties;
  headingPositionDesktop?: CSSProperties;
  overlayPositionMobile?: CSSProperties;
  overlayPositionDesktop?: CSSProperties;
  addtionalImgSrc?: string;
  arrowPositionMobile?: CSSProperties; // 新增的箭头位置
  showArrow?: boolean; // 新增的 prop
  maintainAspectRatio?: boolean; // 新增的 prop
}

const Banner: React.FC<BannerProps> = ({
  videoSrcMobile,
  videoSrcDesktop,
  altText,
  heading,
  className,
  overlayImgSrc,
  overlayClassName,
  headingClassName,
  additionalContent,
  videoClassName,
  button,
  buttonPositionMobile,
  buttonPositionDesktop,
  headingPositionMobile,
  headingPositionDesktop,
  overlayPositionMobile,
  overlayPositionDesktop,
  addtionalImgSrc,
  arrowPositionMobile, // 新增的箭头位置
  showArrow = true, // 新增的 prop，默认为 true
  maintainAspectRatio = false // 新增的 prop，默认为 false
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isGif = (src: string) => src.toLowerCase().endsWith('.gif');

  const videoStyles: CSSProperties = isMobile && maintainAspectRatio 
    ? { width: '100%', height: '100%', objectFit: 'cover' }
    : { width: '100%', height: '100%' };

  const containerStyles: CSSProperties = isMobile && maintainAspectRatio 
    ? { position: 'relative', paddingTop: '56.25%'}
    : { height: '100vh' };

  return (
    <section className={`relative flex flex-col justify-center items-center w-full overflow-hidden ${className}`} style={containerStyles}>
      {isMobile ? (
        isGif(videoSrcMobile) ? (
          <img
            src={videoSrcMobile}
            alt={altText}
            className={`${videoClassName}`}
            style={videoStyles}
          />
        ) : (
          <video
            src={videoSrcMobile}
            className={`${videoClassName}`}
            autoPlay
            loop
            muted
            playsInline
            style={videoStyles}
          >
            <track kind="captions" srcLang="en" label={altText} />
          </video>
        )
      ) : (
        <video
          src={videoSrcDesktop}
          className={`${videoClassName}`}
          autoPlay
          loop
          muted
          playsInline
          style={videoStyles}
        >
          <track kind="captions" srcLang="en" label={altText} />
        </video>
      )}
      {overlayImgSrc && (
        <img
          src={overlayImgSrc}
          alt="Overlay"
          className={`absolute z-10 ${overlayClassName}`}
          style={isMobile ? overlayPositionMobile : overlayPositionDesktop}
        />
      )}
      <div
        className={`${headingClassName}`}
        style={isMobile ? headingPositionMobile : headingPositionDesktop}
      >
        {heading}
      </div>
      <div
        className={`absolute z-20`}
        style={isMobile ? buttonPositionMobile : buttonPositionDesktop}
      >
        {button}
      </div>
      {isMobile && showArrow && <Arrow className="z-20" style={arrowPositionMobile} />}
      {addtionalImgSrc && (
        <img
          src={addtionalImgSrc}
          alt=""
          className={`absolute z-0`}
          style={{ bottom: '-0.5rem', width: '100%' }}
        />
      )}
      {additionalContent && (
        <div className="relative z-20 w-full text-center mt-10" style={{ marginTop: '2.5rem' }}>
          {additionalContent}
        </div>
      )}
    </section>
  );
};

export default Banner;
 */

/* import React, { useState, useEffect, CSSProperties } from "react";
import Arrow from './Arrow'; // 确保路径正确

interface BannerProps {
  videoSrcMobile: string;
  videoSrcDesktop: string;
  altText: string;
  heading: React.ReactNode;
  className?: string;
  overlayImgSrc?: string;
  overlayClassName?: string;
  headingClassName?: string;
  additionalContent?: React.ReactNode;
  button?: React.ReactNode;
  videoClassName?: string;
  buttonPositionMobile?: CSSProperties;
  buttonPositionDesktop?: CSSProperties;
  headingPositionMobile?: CSSProperties;
  headingPositionDesktop?: CSSProperties;
  overlayPositionMobile?: CSSProperties;
  overlayPositionDesktop?: CSSProperties;
  addtionalImgSrc?: string;
  arrowPositionMobile?: CSSProperties; // 新增的箭头位置
  showArrow?: boolean; // 新增的 prop
}

const Banner: React.FC<BannerProps> = ({
  videoSrcMobile,
  videoSrcDesktop,
  altText,
  heading,
  className,
  overlayImgSrc,
  overlayClassName,
  headingClassName,
  additionalContent,
  videoClassName,
  button,
  buttonPositionMobile,
  buttonPositionDesktop,
  headingPositionMobile,
  headingPositionDesktop,
  overlayPositionMobile,
  overlayPositionDesktop,
  addtionalImgSrc,
  arrowPositionMobile, // 新增的箭头位置
  showArrow = true // 新增的 prop，默认为 true
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className={`relative flex flex-col justify-center items-center min-h-screen w-full overflow-hidden ${className}`}>
      <video
        src={isMobile ? videoSrcMobile : videoSrcDesktop}
        className={`${videoClassName}`}
        autoPlay
        loop
        muted
        playsInline
        style={{ width: '100%', height: '100%' }}
      >
        <track kind="captions" srcLang="en" label={altText} />
      </video>
      {overlayImgSrc && (
        <img
          src={overlayImgSrc}
          alt="Overlay"
          className={`absolute z-10 ${overlayClassName}`}
          style={isMobile ? overlayPositionMobile : overlayPositionDesktop}
        />
      )}
      <div
        className={`${headingClassName}`}
        style={isMobile ? headingPositionMobile : headingPositionDesktop}
      >
        {heading}
      </div>
      <div
        className={`absolute z-20`}
        style={isMobile ? buttonPositionMobile : buttonPositionDesktop}
      >
        {button}
      </div>
      {isMobile && showArrow && <Arrow className="z-20" style={arrowPositionMobile} />}
      {addtionalImgSrc && (
        <img
          src={addtionalImgSrc}
          alt=""
          className={`absolute z-0`}
          style={{ bottom: '-0.5rem', width: '100%' }}
        />
      )}
      {additionalContent && (
        <div className="relative z-20 w-full text-center mt-10" style={{ marginTop: '2.5rem' }}>
          {additionalContent}
        </div>
      )}
    </section>
  );
};

export default Banner;
 */



/* import React, { useState, useEffect, CSSProperties } from "react";

interface BannerProps {
  videoSrcMobile: string;
  videoSrcDesktop: string;
  altText: string;
  heading: React.ReactNode;
  className?: string;
  overlayImgSrc?: string;
  overlayClassName?: string;
  headingClassName?: string;
  additionalContent?: React.ReactNode;
  button?: React.ReactNode;
  videoClassName?: string;
  buttonPositionMobile?: CSSProperties;
  buttonPositionDesktop?: CSSProperties;
  headingPositionMobile?: CSSProperties;
  headingPositionDesktop?: CSSProperties;
  overlayPositionMobile?: CSSProperties;
  overlayPositionDesktop?: CSSProperties;
  addtionalImgSrc?: string;
}

const Banner: React.FC<BannerProps> = ({
  videoSrcMobile,
  videoSrcDesktop,
  altText,
  heading,
  className,
  overlayImgSrc,
  overlayClassName,
  headingClassName,
  additionalContent,
  videoClassName,
  button,
  buttonPositionMobile,
  buttonPositionDesktop,
  headingPositionMobile,
  headingPositionDesktop,
  overlayPositionMobile,
  overlayPositionDesktop,
  addtionalImgSrc
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className={`relative flex flex-col justify-center items-center min-h-screen w-full overflow-hidden ${className}`}>
      <video
        src={isMobile ? videoSrcMobile : videoSrcDesktop}
        className={`${videoClassName}`}
        autoPlay
        loop
        muted
        playsInline
        style={{ width: '100%', height: '100%' }}
      >
        <track kind="captions" srcLang="en" label={altText} />
      </video>
      {overlayImgSrc && (
        <img
          src={overlayImgSrc}
          alt="Overlay"
          className={`absolute z-10 ${overlayClassName}`}
          style={isMobile ? overlayPositionMobile : overlayPositionDesktop}
        />
      )}
      <div
        className={`${headingClassName}`}
        style={isMobile ? headingPositionMobile : headingPositionDesktop}
      >
        {heading}
      </div>
      <div
        className={`absolute z-20`}
        style={isMobile ? buttonPositionMobile : buttonPositionDesktop}
      >
        {button}
      </div>
      {addtionalImgSrc && (
        <img
          src={addtionalImgSrc}
          alt=""
          className={`absolute z-0`}
          style={{ bottom: '-0.5rem', width: '100%' }}
        />
      )}
      {additionalContent && (
        <div className="relative z-20 w-full text-center mt-10" style={{ marginTop: '2.5rem' }}>
          {additionalContent}
        </div>
      )}
    </section>
  );
};

export default Banner;
 */

/* import React, { useState, useEffect, CSSProperties } from "react";

interface BannerProps {
  videoSrc: string;
  altText: string;
  heading: React.ReactNode;
  className?: string;
  overlayImgSrc?: string;
  overlayClassName?: string;
  headingClassName?: string;
  additionalContent?: React.ReactNode;
  button?: React.ReactNode;
  videoClassName?: string;
  buttonPosition?: CSSProperties;
  headingPosition?: CSSProperties;
  overlayPosition?: CSSProperties;
  addtionalImgSrc?: string;
}

const Banner: React.FC<BannerProps> = ({
  videoSrc,
  altText,
  heading,
  className,
  overlayImgSrc,
  overlayClassName,
  headingClassName,
  additionalContent,
  videoClassName,
  button,
  buttonPosition,
  headingPosition,
  overlayPosition,
  addtionalImgSrc
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className={`relative flex flex-col justify-center items-center min-h-screen w-full overflow-hidden ${className}`}>
      <video
        src={videoSrc}
        className={`${videoClassName}`}
        autoPlay
        loop
        muted
        playsInline
        style={{ width: '100%', height: '100%' }}
      >
        <track kind="captions" srcLang="en" label={altText} />
      </video>
      {overlayImgSrc && (
        <img
          src={overlayImgSrc}
          alt="Overlay"
          className={`absolute z-10 ${overlayClassName}`}
          style={{ ...overlayPosition, ...(isMobile ? { width: '10rem' } : {}) }} // 调整图片大小
        />
      )}
      <div
        className={`${headingClassName}`}
        style={{
          ...headingPosition,
          ...(isMobile ? { fontSize: '1rem', top: '30%' } : {})
        }}
      >
        {heading}
      </div>
      <div
        className={`absolute z-20`}
        style={{
          ...buttonPosition,
          ...(isMobile ? { top: '45%', padding: '0.5rem 1rem' } : {})
        }}
      >
        {button}
      </div>
      {addtionalImgSrc && (
        <img
          src={addtionalImgSrc}
          alt=""
          className={`absolute z-0`}
          style={{ bottom: '-0.5rem', width: '100%' }}
        />
      )}
      {additionalContent && (
        <div className="relative z-20 w-full text-center mt-10" style={{ marginTop: '2.5rem' }}>
          {additionalContent}
        </div>
      )}
    </section>
  );
};

export default Banner; */




/* import * as React from "react";

interface BannerProps {
  videoSrc: string;
  altText: string;
  heading: React.ReactNode;
  className?: string;
  overlayImgSrc?: string;
  overlayClassName?: string;
  headingClassName?: string;
  additionalContent?: React.ReactNode;
  button?: React.ReactNode;
  videoClassName?: string;
  buttonPosition?: string;
  addtionalImage?: string;
  addtionalImgSrc?: string;
}

const Banner: React.FC<BannerProps> = ({
  videoSrc,
  altText,
  heading,
  className,
  overlayImgSrc,
  overlayClassName,
  headingClassName,
  additionalContent,
  videoClassName,
  button,
  buttonPosition,
  addtionalImgSrc
}) => {
  return (
    <section className={`flex relative flex flex-col justify-center items-center min-h-screen w-full overflow-hidden ${className}`}>
      <video
        src={videoSrc}
        className={`${videoClassName}`}
        autoPlay
        loop
        muted
        playsInline
      >
        <track kind="captions" srcLang="en" label={altText} />
      </video>
      {overlayImgSrc && (
        <img
          src={overlayImgSrc}
          alt="Overlay"
          className={`absolute z-10 ${overlayClassName}`}
        />
      )}
      <div className={`${headingClassName}`}>
        {heading}
      </div>
      <div className={`absolute z-20 ${buttonPosition}`}>
        {button}
      </div>
      {addtionalImgSrc && (
        <img
          src={addtionalImgSrc}
          alt=""
          className={`absolute z-0 bottom-[-8px] w-full`}
        />
      )}
      {additionalContent && (
        <div className="relative z-20 w-full text-center mt-10">
          {additionalContent}
        </div>
      )}
    </section>
  );
};

export default Banner; */
