import request from './request';

export const addAnimal = (data: any) => {
  return request({
    url: '/addAnimal',
    method: 'post',
    data
  });
}
export const addAnimalArtwork = (data: any) => {
  return request({
    url: '/addAnimalArtwork',
    method: 'post',
    data
  });
}

export const removeArtwork = (data: any) => {
  return request({
    url: '/removeArtwork',
    method: 'post',
    data
  });
}

export const removeArtists = (data: any) => {
  return request({
    url: '/removeArtists',
    method: 'post',
    data
  });
}

export const addAnimalTag = (data: any) => {
  return request({
    url: '/addAnimalTag',
    method: 'post',
    data
  });
}

export const getArtistsTag = () => {
  return request({
    url: '/getArtistsTag',
    method: 'get'
  });
}
export const getAllNames = () => {
  return request({
    url: '/getAllNames',
    method: 'get'
  });
}

export const getArtistss = (params?: any) => {
  if (params) {
    return request({
      url: '/getArtistss',
      method: 'get',
      params
    });
  }
  return request({
    url: '/getArtistss',
    method: 'get',
  });
}

export const getArtistsArtwork = (params?: any) => {
  if (params) {
    return request({
      url: '/getArtistsArtwork',
      method: 'get',
      params,
    });
  }
  return request({
    url: '/getArtistsArtwork',
    method: 'get'
  });
}

export const getArtistsArtworkByRandom = (data?: any) => {
  return request({
    url: '/getArtistsArtworkByRandom',
    method: 'post',
    data
  });
}
export const getMyFavorite = (data?: any) => {
  return request({
    url: '/getMyFavorite',
    method: 'post',
    data
  });
}

export const getArtistsCoverByRandom = (data?: any) => {
  return request({
    url: '/getArtistsCoverByRandom',  // 更新URL以获取Cover数据
    method: 'post',
    data
  });
};

export const getCastCoverByRandom = (data?: any) => {
  return request({
    url: '/getCastCoverByRandom',  // 更新URL以获取Cover数据
    method: 'post',
    data
  });
};