// src/ImageUpload.tsx
import React, { useEffect, useState } from 'react';
import { message, Upload, Button, Image } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { s3Client, ImageBaseUrl } from '../grocery/s3';

interface ImageUploadProps {
  onUploadSuccess?: (url: string) => void;
  initialImageUrl?: string;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onUploadSuccess, initialImageUrl }) => {
  const [imageUrl, setImageUrl] = useState<any>(initialImageUrl);

  useEffect(() => {
    setImageUrl(initialImageUrl);
  }, [initialImageUrl]);

  // 检查文件是否为图片
  const isImage = (file: RcFile) => {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
    if (!acceptedImageTypes.includes(file.type)) {
      message.error('You can only upload image files!');
      return false;
    }
    return true;
  };

  // 文件上传之前的逻辑
  const beforeUpload = async (file: RcFile) => {
    if (!isImage(file)) {
      return false;
    }
    console.log('beforeUpload', file);
    try {
      const folder = 'images';
      const bucketName = 'shihoo';
      const fileName = Date.now() + file.name;
      const remoteFilePath = `${folder}/` + fileName;
      const params = {
        Bucket: bucketName,
        Key: remoteFilePath,
        Body: file,
        ACL: 'public-read' as 'public-read', // 强制将字符串字面量类型为 'public-read'
      };
      const res = await s3Client.send(new PutObjectCommand(params));
      if (res) {
        message.success(`${file.name} file uploaded successfully`);
        const uploadUrl = `${ImageBaseUrl}/${folder}/${fileName}`;
        setImageUrl(uploadUrl);
        onUploadSuccess?.(uploadUrl);
      }
      return false;
    } catch (error) {
      console.error('Upload error:', error); 
      message.error(`${file.name} file upload failed.`);
      return false;
    }
  };

  return (
    <div className='flex flex-col'>
      <Upload
        showUploadList={false}
        beforeUpload={beforeUpload}
      >
        <Button>{imageUrl ? "Reupload" : "Upload Image"}</Button>
      </Upload>
      {imageUrl && <Image src={imageUrl} width={100} alt="Uploaded" className='m-2' />}
    </div>
  );
};

export default ImageUpload;
