import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../component/Header';
import TypingEffect from '../component/TypingEffect';
import ImageFadeIn from '../component/ImageFadeIn';
import BackGround from '../component/BackGround';
import useAuthStore from '../authStore';
import Footer from '../component/Footer';
import Information from '../component/Information';
import EventList from '../component/EventList';
import JobInformation from '../component/JobInformation';
import Banner from '../component/Banner';
import CastWall from '../component/CastWall';

const DescriptionSection: React.FC<{ heading: string; subheading: React.ReactNode; text: React.ReactNode; descridis: string }> = ({
  heading,
  subheading,
  text,
  descridis
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className={`flex flex-col px-5 max-md:mt-10 max-md:max-w-full ${descridis}`}>
      <h2 className={`text-xl text-left font-medium text-orange-800 max-md:max-w-full ${isMobile ? 'text-lg' : ''}`}>{heading}</h2>
      <h3 className={`mt-5 text-6xl text-left text-orange-100 uppercase max-md:max-w-full ${isMobile ? 'text-lg' : ''}`}>{subheading}</h3>
      <div className={`mt-14 text-sm leading-6 text-pink-400 max-md:mt-10 max-md:max-w-full text-left ${isMobile ? 'text-xs leading-5' : ''}`}>
        {text}
      </div>
    </section>
  );
};

function Home() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();
  const [step, setStep] = useState<number>(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 6);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleReservationClick = () => {
    window.location.href = "https://forms.gle/9uZrBhn4s3CCek9W9";
  };

  return (
    <div className="Home max-w-[1440px] w-full mx-auto">
      <Header />
      <BackGround />

      <Banner
        videoSrcMobile="https://shihoo.sgp1.cdn.digitaloceanspaces.com/video_or_gif/bannerphone.m4v"
        videoSrcDesktop="https://shihoo.sgp1.cdn.digitaloceanspaces.com/video_or_gif/Homepage_Cover_Video.mp4"
        altText="SHIRHOO"
        heading={
          <span>
            奢华之境,佳人相伴,醉心日本<br />
            一切尽在银座Shihoo 紫峰高级俱乐部
          </span>
        }
        className="font-sans"
        overlayImgSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/vector.png"
        overlayClassName="flex absolute z-10"
        headingClassName="flex absolute lg:text-2xl sm:text-xl whitespace-nowrap"
        videoClassName="flex absolute inset-0 w-full h-full object-cover"
        addtionalImgSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/blackshaow.png"
        overlayPositionMobile={{ top: '30%', left: '50%', transform: 'translate(-50%, -50%)', width: '10rem', height: 'auto' }} // 自定义手机端图片位置
        overlayPositionDesktop={{ top: '35%', left: '50%', transform: 'translate(-50%, -50%)', width: '25rem', height: 'auto' }} // 自定义桌面端图片位置
        headingPositionMobile={{ top: '38%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '1rem' }} // 自定义手机端heading位置
        headingPositionDesktop={{ top: '43%', left: '50%', transform: 'translate(-50%, -50%)' }} // 自定义桌面端heading位置
        buttonPositionMobile={{ top: '44%', left: '50%', transform: 'translate(-50%, 0)', padding: '0.5rem 1rem' }} // 自定义手机端按钮位置
        buttonPositionDesktop={{ top: '50%', left: '50%', transform: 'translate(-50%, 0)' }} // 自定义桌面端按钮位置
        arrowPositionMobile={{ top: '80%', left: '47%', transform: 'translate(-50%, -50%)' }} // 自定义箭头位置
        showArrow={true} // 控制箭头显示
        maintainAspectRatio={false}
        button={
          <div className="flex gap-5 justify-between py-3 pr-5 pl-5 backdrop-blur-[7.40625px] rounded-[40px_40px_40px_0px] max-w-md mx-auto max-md:px-3" onClick={handleReservationClick}>
            <div className="flex flex-col text-white whitespace-nowrap">
              <span className="text-md leading-[24px] mr-12 ml-12">Reservation</span>
              <h3 className="mt-1 text-md text-center leading-[24px] mr-12 ml-12">即刻預定</h3>
            </div>
            <div className="flex flex-col justify-center items-start my-auto">
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/arrow_icon.png"
                className="rounded-full w-[45px] h-auto"
                alt="Reservation icon"
              />
            </div>
          </div>
        }
      />
      <div className="flex flex-col items-center space-y-4 text-white mt-8">
        <img
          src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/dasdasdsad.png"
          alt="Additional Content Image"
          className="w-1/2 h-auto max-md:w-3/4"
        />
        <div>
          <p className="text-2xl mt-4 font-extralight text-center text-white leading-[50px] max-w-[878px] max-md:text-xl max-md:leading-8">A lady who seeks solace for her heart invites you to a moment of peace</p>
        </div>
      </div>


      <div className="flex flex-col mt-12">
        <article id="aboutUs" className="mt-32 w-full max-md:mt-10 max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4 items-start`}>
            <figure className={`flex flex-col ${isMobile ? 'order-2 w-full' : 'w-1/2'} items-start`}>
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/Frame%2063.jpg"
                alt="Image of the establishment"
                className="grow w-full object-contain max-md:max-w-full pl-2 pr-2"
              />
            </figure>
            <div className={`flex flex-col justify-start ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="关于我们"
                subheading={<div className={` ${isMobile ? '' : 'text-[40px]'}`}>
                  ABOUT US
                  </div>}
                descridis="ml-0 mr-0"
                text={
                  <div className={`self-center text-left text-white text-left pl-2 pr-2 ${isMobile ? 'text-base leading-6 mt-0' : 'text-[15px] leading-9 mt-0'}`}>
                    <span className="text-white">欢迎来到</span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      高级俱乐部，这是一个让您尽情放松、品味美酒、享受精致生活的私密空间。我们以独特的氛围、精心挑选的顶级酒品和无与伦比的服务而闻名，为您提供一场超凡的饮酒体验。
                      <br />
                    </span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      是一个融合传统文化与现代奢华的绝佳场所，为您提供独一无二的饮酒体验。我们致力于为您呈现日本精酿酒、威士忌和烈酒的精髓，让您尽情沉浸在日本酒文化的深厚魅力中。
                      <br />
                      <br />
                      无论您是日本酒的忠实粉丝还是对日本文化感兴趣的旅行者，我们的高级喝酒俱乐部都将为您带来一场无与伦比的日本酒之旅，让您在品味的同时感受到日本文化的独特魅力。
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article>

        <article id="aboutGirls" className="mt-32 w-full max-md:mt-10 max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4 items-start`}>
            <figure className={`flex flex-col ${isMobile ? 'order-2 w-full' : 'w-1/2'} items-start`}>
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/information_aboutgirls.jpg"
                alt="Image of the staff"
                className="grow w-full object-contain max-md:max-w-full pl-2 pr-2"
              />
            </figure>
            <div className={`flex flex-col justify-start ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="关于女公關"
                subheading={<div className={` ${isMobile ? '' : 'text-[40px]'}`}>
                ABOUT GIRLS
                </div>}
                descridis="ml-0 mr-0"
                text={
                  <div className={`self-center text-left text-white text-left pl-2 pr-2 ${isMobile ? 'text-base leading-6 mt-0' : 'text-[15px] leading-9 mt-0'}`}>
                    <span className="text-white">欢迎来到</span>
                    <span className="text-pink-400">银座紫峰SHIHOO<br /></span>
                    <span className="text-white ">
                      在这里，我们为您打造了一个优雅与奢华交织空间。我们的女公关会以迷人风采和温婉气质，为您带来前所未有的享受。<br />
                      <br />
                      并且，我们会不定期邀请日本知名女优空降，与您零距离互动，品味美酒，共度难忘时光，深入感受日本文化的独特魅力。您在这里的每一次体验都将是愉悦与惊喜的旅程，我们的宗旨是“服务不仅是工作，更是艺术”，旨在让您领略酒吧文化的精髓和魅力
                      <br /><br />
                      一期一会，让您的每次光临都成为难忘的回忆，尽情享受在东京的每一个瞬间。
                      <br />
                      <br />
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article>


        {/* <article id="aboutUs" className="mt-32 w-full max-md:mt-10 max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4`}>
            <figure className={`flex flex-col ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/Frame%2063.jpg"
                alt="Image of the establishment"
                className="grow mt-6 w-full object-contain max-md:mt-10 max-md:max-w-full"
              />
            </figure>
            <div className={`flex flex-col justify-center ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="关于我们"
                subheading="ABOUT US"
                descridis="ml-0 mr-0"
                text={
                  <div className={`self-center text-left text-white text-left ${isMobile ? 'text-base leading-6 mt-0' : 'text-2xl leading-9 mt-12'}`}>
                    <span className="text-white">欢迎来到</span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      高级俱乐部，这是一个让您尽情放松、品味美酒、享受精致生活的私密空间。我们以独特的氛围、精心挑选的顶级酒品和无与伦比的服务而闻名，为您提供一场超凡的饮酒体验。
                      <br />
                      <br />
                    </span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      是一个融合传统文化与现代奢华的绝佳场所，为您提供独一无二的饮酒体验。我们致力于为您呈现日本精酿酒、威士忌和烈酒的精髓，让您尽情沉浸在日本酒文化的深厚魅力中。
                      <br />
                      <br />
                      无论您是日本酒的忠实粉丝还是对日本文化感兴趣的旅行者，我们的高级喝酒俱乐部都将为您带来一场无与伦比的日本酒之旅，让您在品味的同时感受到日本文化的独特魅力。
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article> */}
        {/*  <article id="aboutGirls" className="mt-32 w-full max-md:mt-10 max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4`}>
            <figure className={`flex flex-col ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/information_aboutgirls.jpg"
                alt="Image of the staff"
                className="grow mt-6 w-full object-contain max-md:mt-10 max-md:max-w-full"
              />
            </figure>
            <div className={`flex flex-col justify-center ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="关于女孩"
                subheading="About Girls"
                descridis="ml-0 mr-0"
                text={
                  <div className={`self-center text-left text-white text-left ${isMobile ? 'text-base leading-6 mt-0' : 'text-xl leading-9 mt-0'}`}>
                    <span className="text-white">欢迎来到</span>
                    <span className="text-pink-400">银座紫峰SHIHOO<br /></span>
                    <span className="text-white ">
                      在这里，我们为您打造了一个优雅与奢华交织空间。我们的女公关会以迷人风采和温婉气质，为您带来前所未有的享受。<br />
                      <br />
                      并且，我们会不定期邀请日本知名女优空降，与您零距离互动，品味美酒，共度难忘时光，深入感受日本文化的独特魅力。您在这里的每一次体验都将是愉悦与惊喜的旅程，我们的宗旨是“服务不仅是工作，更是艺术”，旨在让您领略酒吧文化的精髓和魅力
                      <br /><br />
                      一期一会，让您的每次光临都成为难忘的回忆，尽情享受在东京的每一个瞬间。
                      <br />
                      <br />
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article> */}
      </div>
      <CastWall />
      <EventList id="eventsNews" />
      <JobInformation id="recruit" />
      <Banner
        containerHeight="50vh"
        videoSrcMobile="https://shihoo.sgp1.cdn.digitaloceanspaces.com/video_or_gif/bannerphonesub.GIF"
        videoSrcDesktop="https://shihoo.sgp1.cdn.digitaloceanspaces.com/video_or_gif/Homepage_Cover_ShortVideo.m4v"
        altText="SHIRHOO"
        heading={
          <span>
            Premium enjoyment<br />
            in Tokyo
          </span>
        }
        headingClassName={`flex absolute font-montserrat-extra-bold ${isMobile ? 'text-xl' : 'text-[80px]'}`}
        //headingClassName="flex absolute lg:text-2xl sm:text-xl font-montserrat-extra-bold"
        headingPositionMobile={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '1rem' }} // 自定义手机端heading位置
        className="font-sans"
        videoClassName='object-cover absolute inset-0 w-full h-[75vh]'
        showArrow={false}
        maintainAspectRatio={true}
      />
      <Information id="storeLocation" />
      <Footer />
    </div>
  );
}

export default Home;

/* import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../component/Header';
import TypingEffect from '../component/TypingEffect';
import ImageFadeIn from '../component/ImageFadeIn';
import BackGround from '../component/BackGround';
import useAuthStore from '../authStore';
import Footer from '../component/Footer';
import Information from '../component/Information';
import EventList from '../component/EventList';
import JobInformation from '../component/JobInformation';
import Banner from '../component/Banner';
import CastWall from '../component/CastWall';

const DescriptionSection: React.FC<{ heading: string; subheading: string; text: React.ReactNode; descridis: string }> = ({
  heading,
  subheading,
  text,
  descridis
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className={`flex flex-col px-5 max-md:mt-10 max-md:max-w-full ${descridis}`}>
      <h2 className={`text-xl text-left font-medium text-orange-800 max-md:max-w-full ${isMobile ? 'text-lg' : ''}`}>{heading}</h2>
      <h3 className={`mt-5 text-6xl text-left text-orange-100 uppercase max-md:max-w-full ${isMobile ? 'text-lg' : ''}`}>{subheading}</h3>
      <div className={`mt-14 text-sm leading-6 text-pink-400 max-md:mt-10 max-md:max-w-full text-left ${isMobile ? 'text-xs leading-5' : ''}`}>
        {text}
      </div>
    </section>
  );
};

function Home() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();
  const [step, setStep] = useState<number>(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 6);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="Home max-w-[1440px] w-full mx-auto">
      <Header />
      <BackGround />
      <Banner
        videoSrcMobile="https://shihoo.sgp1.cdn.digitaloceanspaces.com/video_or_gif/bannerphone.m4v"
        videoSrcDesktop="https://shihoo.sgp1.cdn.digitaloceanspaces.com/video_or_gif/Homepage_Cover_Video.mp4"
        altText="SHIRHOO"
        heading={
          <span>
            奢华之境,佳人相伴,醉心日本<br />
            一切尽在银座Shihoo 紫峰高级俱乐部
          </span>
        }
        className="font-sans"
        overlayImgSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/vector.png"
        overlayClassName="flex absolute z-10"
        headingClassName="flex absolute lg:text-2xl sm:text-xl"
        videoClassName="flex absolute inset-0 w-full h-full object-cover"
        addtionalImgSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/blackshaow.png"
        overlayPositionMobile={{ top: '30%', left: '50%', transform: 'translate(-50%, -50%)', width: '10rem', height: 'auto' }} // 自定义手机端图片位置
        overlayPositionDesktop={{ top: '35%', left: '50%', transform: 'translate(-50%, -50%)', width: '25rem', height: 'auto' }} // 自定义桌面端图片位置
        headingPositionMobile={{ top: '38%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '1rem' }} // 自定义手机端heading位置
        headingPositionDesktop={{ top: '43%', left: '50%', transform: 'translate(-50%, -50%)' }} // 自定义桌面端heading位置
        buttonPositionMobile={{ top: '40%', left: '50%', transform: 'translate(-50%, 0)', padding: '0.5rem 1rem' }} // 自定义手机端按钮位置
        buttonPositionDesktop={{ top: '50%', left: '50%', transform: 'translate(-50%, 0)' }} // 自定义桌面端按钮位置
        button={
          <div className="flex gap-5 justify-between py-3 pr-5 pl-5 backdrop-blur-[7.40625px] rounded-[40px_40px_40px_0px] max-w-md mx-auto max-md:px-3">
            <div className="flex flex-col text-white whitespace-nowrap">
              <span className="text-md leading-[24px] mr-12 ml-12">Reservation</span>
              <h3 className="mt-1 text-md text-center leading-[24px] mr-12 ml-12">即刻預定</h3>
            </div>
            <div className="flex flex-col justify-center items-start my-auto">
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/arrow_icon.png"
                className="rounded-full w-[45px] h-auto"
                alt="Reservation icon"
              />
            </div>
          </div>
        }
      />
      <div className="flex flex-col items-center space-y-4 text-white mt-36">
        <img
          src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/banner_addition.png"
          alt="Additional Content Image"
          className="w-1/2 h-auto max-md:w-3/4"
        />
        <div>
          <p className="text-2xl mt-4 font-extralight text-center text-white leading-[50px] max-w-[878px] max-md:text-xl max-md:leading-8">A lady for you who seeks the moisture of the heart, A woman invites you to a moment of peace</p>
        </div>
      </div>
      <div className="flex flex-col mt-36">
        <article id="aboutUs" className="w-full max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4`}>
            <figure className={`flex flex-col ${isMobile ? 'order-1 w-full' : 'w-1/2'}`}>
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/Information_aboutus.jpg"
                alt="Image of the establishment"
                className="grow mt-6 w-full aspect-[1.47] max-md:mt-10 max-md:max-w-full"
              />
            </figure>
            <div className={`flex flex-col justify-center ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="关于我们"
                subheading="ABOUT US"
                descridis="mr-4"
                text={
                  <div className="text-xl">
                    <span className="text-white">欢迎来到</span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      高级俱乐部，这是一个让您尽情放松、品味美酒、享受精致生活的私密空间。我们以独特的氛围、精心挑选的顶级酒品和无与伦比的服务而闻名，为您提供一场超凡的饮酒体验。
                      <br />
                      <br />
                    </span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      是一个融合传统文化与现代奢华的绝佳场所，为您提供独一无二的饮酒体验。我们致力于为您呈现日本精酿酒、威士忌和烈酒的精髓，让您尽情沉浸在日本酒文化的深厚魅力中。
                      <br />
                      <br />
                      无论您是日本酒的忠实粉丝还是对日本文化感兴趣的旅行者，我们的高级喝酒俱乐部都将为您带来一场无与伦比的日本酒之旅，让您在品味的同时感受到日本文化的独特魅力。
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article>
        <article id="aboutGirls" className="mt-32 w-full max-md:mt-10 max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4`}>
            <figure className={`flex flex-col ${isMobile ? 'order-1 w-full' : 'w-1/2'}`}>
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/information_aboutgirls.jpg"
                alt="Image of the staff"
                className="grow mt-6 w-full aspect-[1.47] max-md:mt-10 max-md:max-w-full"
              />
            </figure>
            <div className={`flex flex-col justify-center ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="关于我们"
                subheading="About Girls"
                descridis="ml-4 mr-0"
                text={
                  <div className="text-xl">
                    <span className="text-white">欢迎来到</span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      高级俱乐部 我们拥有优雅而迷人的日本女性服务人员,不定期的日本大牌成人女演员空降,与你面对面交谈,饮酒,带你领略日本文化的独特魅力。<br />
                      <br />
                      我们希望在陪伴客人期间,您能仿佛置身于一个温馨而充满人情味的环境中,与我们的每一次的互动都充满着愉悦和惊喜。
                      <br />
                      我们的服务不仅是一种工作,更是一种艺术,让人们体验到酒吧文化的精髓和魅力,为每一次的光临带来难忘的记忆。
                      <br />
                      <br />
                      希望您能在
                    </span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      高级俱乐部,感受到来自日本女性最棒的服务,请享受在东京。
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article>
      </div>
      <EventList id="eventsNews" />
      <JobInformation id="recruit" />
      <Information id="storeLocation" />
      <Footer />
    </div>
  );
}

export default Home;
 */



/* import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../component/Header';
import TypingEffect from '../component/TypingEffect';
import ImageFadeIn from '../component/ImageFadeIn';
import BackGround from '../component/BackGround';
import useAuthStore from '../authStore';
import Footer from '../component/Footer';
import Information from '../component/Information';
import EventList from '../component/EventList';
import JobInformation from '../component/JobInformation';
import Banner from '../component/Banner';
import CastWall from '../component/CastWall';

const DescriptionSection: React.FC<{ heading: string; subheading: string; text: React.ReactNode; descridis: string }> = ({
  heading,
  subheading,
  text,
  descridis
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className={`flex flex-col px-5 max-md:mt-10 max-md:max-w-full ${descridis}`}>
      <h2 className="text-xl text-left font-medium text-orange-800 max-md:max-w-full">{heading}</h2>
      <h3 className="mt-5 text-6xl text-left text-orange-100 uppercase max-md:max-w-full max-md:text-4xl">{subheading}</h3>
      <div className="mt-14 text-sm leading-6 text-pink-400 max-md:mt-10 max-md:max-w-full text-left">
        {text}
      </div>
    </section>
  );
};

function Home() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();
  const [step, setStep] = useState<number>(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 6);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="Home max-w-[1440px] w-full mx-auto">
      <Header />
      <BackGround />
      <Banner
      videoSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/video_or_gif/Homepage_Cover_Video.mp4"
      altText="SHIRHOO"
      heading={
        <span>
          奢华之境,佳人相伴,醉心日本<br />
          一切尽在银座Shihoo 紫峰高级俱乐部
        </span>
      }
      className="font-sans"
      overlayImgSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/vector.png"
      overlayClassName="flex absolute z-10"
      headingClassName="flex absolute lg:text-2xl sm:text-xl"
      videoClassName="flex absolute inset-0 w-full h-full object-cover"
      addtionalImgSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/blackshaow.png"
      overlayPosition={{ top: '35%', left: '50%', transform: 'translate(-50%, -50%)', width: '25rem', height: 'auto' }} // 设置图片位置
      headingPosition={{ top: '43%', left: '50%', transform: 'translate(-50%, -50%)' }} // 设置heading位置
      buttonPosition={{ top: '50%', left: '50%', transform: 'translate(-50%, 0)' }} // 设置按钮位置
      button={
        <div className="flex gap-5 justify-between py-3 pr-5 pl-5 backdrop-blur-[7.40625px] rounded-[40px_40px_40px_0px] max-w-md mx-auto max-md:px-3">
          <div className="flex flex-col text-white whitespace-nowrap">
            <span className="text-md leading-[24px] mr-12 ml-12">Reservation</span>
            <h3 className="mt-1 text-md text-center leading-[24px] mr-12 ml-12">即刻預定</h3>
          </div>
          <div className="flex flex-col justify-center items-start my-auto">
            <img
              loading="lazy"
              src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/arrow_icon.png"
              className="rounded-full w-[45px] h-auto"
              alt="Reservation icon"
            />
          </div>
        </div>
      }
    />

      <div className="flex flex-col items-center space-y-4 text-white mt-36">
        <img
          src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/banner_addition.png"
          alt="Additional Content Image"
          className="w-1/2 h-auto max-md:w-3/4"
        />
        <div>
          <p className="text-2xl mt-4 font-extralight text-center text-white leading-[50px] max-w-[878px] max-md:text-xl max-md:leading-8">A lady for you who seeks the moisture of the heart, A woman invites you to a moment of peace</p>
        </div>
      </div>

      <div className="flex flex-col mt-36">
        <article id="aboutUs" className="w-full max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4`}>
            <figure className={`flex flex-col ${isMobile ? 'order-1 w-full' : 'w-1/2'}`}>
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/Information_aboutus.jpg"
                alt="Image of the establishment"
                className="grow mt-6 w-full aspect-[1.47] max-md:mt-10 max-md:max-w-full"
              />
            </figure>
            <div className={`flex flex-col justify-center ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="关于我们"
                subheading="ABOUT US"
                descridis="mr-4"
                text={
                  <div className="text-xl">
                    <span className="text-white">欢迎来到</span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      高级俱乐部，这是一个让您尽情放松、品味美酒、享受精致生活的私密空间。我们以独特的氛围、精心挑选的顶级酒品和无与伦比的服务而闻名，为您提供一场超凡的饮酒体验。
                      <br />
                      <br />
                    </span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      是一个融合传统文化与现代奢华的绝佳场所，为您提供独一无二的饮酒体验。我们致力于为您呈现日本精酿酒、威士忌和烈酒的精髓，让您尽情沉浸在日本酒文化的深厚魅力中。
                      <br />
                      <br />
                      无论您是日本酒的忠实粉丝还是对日本文化感兴趣的旅行者，我们的高级喝酒俱乐部都将为您带来一场无与伦比的日本酒之旅，让您在品味的同时感受到日本文化的独特魅力。
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article>

        <article id="aboutGirls" className="mt-32 w-full max-md:mt-10 max-md:max-w-full">
          <section className={`flex gap-4 px-4 ${isMobile ? 'flex-col' : ''} max-md:gap-4`}>
            <figure className={`flex flex-col ${isMobile ? 'order-1 w-full' : 'w-1/2'}`}>
              <img
                loading="lazy"
                src="https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/information_aboutgirls.jpg"
                alt="Image of the staff"
                className="grow mt-6 w-full aspect-[1.47] max-md:mt-10 max-md:max-w-full"
              />
            </figure>
            <div className={`flex flex-col justify-center ${isMobile ? 'order-2 w-full' : 'w-1/2'}`}>
              <DescriptionSection
                heading="关于我们"
                subheading="About Girls"
                descridis="ml-4 mr-0"
                text={
                  <div className="text-xl">
                    <span className="text-white">欢迎来到</span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      高级俱乐部 我们拥有优雅而迷人的日本女性服务人员,不定期的日本大牌成人女演员空降,与你面对面交谈,饮酒,带你领略日本文化的独特魅力。<br />
                      <br />
                      我们希望在陪伴客人期间,您能仿佛置身于一个温馨而充满人情味的环境中,与我们的每一次的互动都充满着愉悦和惊喜。
                      <br />
                      我们的服务不仅是一种工作,更是一种艺术,让人们体验到酒吧文化的精髓和魅力,为每一次的光临带来难忘的记忆。
                      <br />
                      <br />
                      希望您能在
                    </span>
                    <span className="text-pink-400">银座紫峰SHIHOO</span>
                    <span className="text-white">
                      高级俱乐部,感受到来自日本女性最棒的服务,请享受在东京。
                    </span>
                  </div>
                }
              />
            </div>
          </section>
        </article>
      </div>

      <EventList id="eventsNews" />
      <Banner
        videoSrc="https://shihoo.sgp1.cdn.digitaloceanspaces.com/video_or_gif/Homepage_Cover_ShortVideo.m4v"
        altText="SHIRHOO"
        heading={
          <span className='font-montserrat-extra-bold text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl leading-tight tracking-wider'>
            Premium enjoyment<br />
            in Tokyo
          </span>
        }
        headingClassName='relative z-20 flex flex-col justify-center items-center w-full h-full text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        className="font-sans"
        videoClassName='object-cover absolute inset-0 w-full h-[75vh]'
      />
      <JobInformation id="recruit" />
      <Information id="storeLocation" />
      <Footer />
    </div>
  );
}

export default Home;
 */
