import React, { useState, useEffect } from 'react';

interface CardProps {
  imgSrc: string;
  imgAlt: string;
  date: string;
  jobTitle: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ imgSrc, imgAlt, date, jobTitle, description }) => (
  <article className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
    <div className="flex flex-col grow max-md:mt-10">
      <img loading="lazy" src={imgSrc} alt={imgAlt} className="w-full aspect-[1.52]" />
      <time className="mt-6 text-sm leading-7 text-orange-200">
        <span>{date} | </span>
        <span className="text-orange-200">{jobTitle}</span>
      </time>
      <h3 className="mt-5 text-xl font-medium leading-7 text-orange-100">{description}</h3>
    </div>
  </article>
);

const JobInformation: React.FC<{ id?: string }> = ({ id }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cardsData = [
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_mama.jpg",
      imgAlt: "Female Waitress",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihoo Female Waitress"
    },
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_girls.jpg",
      imgAlt: "Club Chick Floor Lady",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihooクラブチック フロアレディ"
    },
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_bartender.jpg",
      imgAlt: "Male Waitress",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihoo Male Waitress"
    }
  ];

  const handleWebApplicationClick = () => {
    window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdsKAQfu4CkHpA5OSoQBDCU6UAOFiTOyY6qawzMLcWBsDOCug/viewform?usp=sf_link";
  };

  return (
    <main id={id} className="flex flex-col px-5 bg-customPurple">
      <header className={`self-center text-xl text-center text-orange-800 ${isMobile ? 'text-base mt-6' : 'text-xl'}`}>Recruit</header>
      <h1 className={`self-center font-bold text-orange-100 leading-[77px] ${isMobile ? 'text-3xl leading-[40px] mt-0' : 'text-6xl mt-12'}`}>求人情報</h1>
      <section className={`self-centertext-center text-orange-100 ${isMobile ? 'text-base leading-6 mt-4' : 'text-2xl leading-9 mt-16'}`}>
        <p>銀座のクラブで働いてみませんか？</p>
        <p>土地柄からお店には大手企業の役員、会社経営者など大人で紳士な方が多く、女の子も安心して働く事ができます。</p>
        <p>週1日からの勤務可能なので、学生さんやタレントのたまご、副業としてもおすすめです。</p>
      </section>
      <section className="px-px mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {cardsData.map((card, index) => (
            <Card
              key={index}
              imgSrc={card.imgSrc}
              imgAlt={card.imgAlt}
              date={card.date}
              jobTitle={card.jobTitle}
              description={card.description}
            />
          ))}
        </div>
      </section>
      <button 
        onClick={handleWebApplicationClick}
        className={`justify-center items-center px-2 py-1 mt-16 w-full font-bold text-white border-2 border-fuchsia-700 border-solid leading-[49.06px] rounded-[40px] ${isMobile ? 'text-xs px-2 py-1' : 'text-2xl'} max-md:px-2 max-md:py-1 max-md:mt-8 max-md:max-w-full`}
      >
        WEB応募 24 時間受付中
      </button>
      <button className={`justify-center items-center px-2 py-1 mt-8 w-full font-bold text-white border-2 border-fuchsia-700 border-solid leading-[49.06px] rounded-[40px] ${isMobile ? 'text-xs px-2 py-1' : 'text-2xl'} max-md:px-2 max-md:py-1 max-md:mt-4 max-md:max-w-full`}>
        電話応募 TEL:03-4362-8777
      </button>
      <div className={`text-neutral-400 mt-12 mb-36 mx-auto leading-[49.06px] max-w-md ${isMobile ? 'text-xs' : 'text-2xl'}`}>
        電話受付時間 : 15~22時
      </div>
    </main>
  );
}

export default JobInformation;





/* import React, { useState, useEffect } from 'react';

interface CardProps {
  imgSrc: string;
  imgAlt: string;
  date: string;
  jobTitle: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ imgSrc, imgAlt, date, jobTitle, description }) => (
  <article className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
    <div className="flex flex-col grow max-md:mt-10">
      <img loading="lazy" src={imgSrc} alt={imgAlt} className="w-full aspect-[1.52]" />
      <time className="mt-6 text-sm leading-7 text-orange-200">
        <span>{date} | </span>
        <span className="text-orange-200">{jobTitle}</span>
      </time>
      <h3 className="mt-5 text-xl font-medium leading-7 text-orange-100">{description}</h3>
    </div>
  </article>
);

const JobInformation: React.FC<{ id?: string }> = ({ id }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cardsData = [
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_mama.jpg",
      imgAlt: "Female Waitress",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihoo Female Waitress"
    },
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_girls.jpg",
      imgAlt: "Club Chick Floor Lady",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihooクラブチック フロアレディ"
    },
    {
      imgSrc: "https://shihoo.sgp1.cdn.digitaloceanspaces.com/Image/job_title_bartender.jpg",
      imgAlt: "Male Waitress",
      date: "April 2024",
      jobTitle: "Job title",
      description: "紫峰Shihoo Male Waitress"
    }
  ];

  const handleWebApplicationClick = () => {
    window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSdsKAQfu4CkHpA5OSoQBDCU6UAOFiTOyY6qawzMLcWBsDOCug/viewform?usp=sf_link";
  };

  return (
    <main id={id} className="flex flex-col px-5 bg-customPurple">
      <header className={`self-center text-xl text-center text-orange-800 ${isMobile ? 'text-lg' : 'text-xl'}`}>Recruit</header>
      <h1 className={`self-center mt-12 font-bold text-orange-100 leading-[77px] ${isMobile ? 'text-4xl leading-[50px]' : 'text-6xl'}`}>求人情報</h1>
      <section className={`self-center mt-16 text-center text-orange-100 ${isMobile ? 'text-lg leading-7' : 'text-2xl leading-9'}`}>
        <p>銀座のクラブで働いてみませんか？</p>
        <p>土地柄からお店には大手企業の役員、会社経営者など大人で紳士な方が多く、女の子も安心して働く事ができます。</p>
        <p>週1日からの勤務可能なので、学生さんやタレントのたまご、副業としてもおすすめです。</p>
      </section>
      <section className="px-px mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {cardsData.map((card, index) => (
            <Card
              key={index}
              imgSrc={card.imgSrc}
              imgAlt={card.imgAlt}
              date={card.date}
              jobTitle={card.jobTitle}
              description={card.description}
            />
          ))}
        </div>
      </section>
      <button 
        onClick={handleWebApplicationClick}
        className={`justify-center items-center px-16 py-3.5 mt-16 w-full font-bold text-white border-2 border-fuchsia-700 border-solid leading-[49.06px] rounded-[40px] ${isMobile ? 'text-xl' : 'text-2xl'} max-md:px-5 max-md:mt-10 max-md:max-w-full`}
      >
        WEB応募 24 時間受付中
      </button>
      <button className={`justify-center items-center px-16 py-3.5 mt-8 w-full font-bold text-white border-2 border-fuchsia-700 border-solid leading-[49.06px] rounded-[40px] ${isMobile ? 'text-xl' : 'text-2xl'} max-md:px-5 max-md:max-w-full`}>
        電話応募 TEL:03-4361-9972
      </button>
      <div className={`text-2xl mt-12 mb-36 mx-auto leading-[49.06px] max-w-md text-neutral-400 ${isMobile ? 'text-xl' : 'text-2xl'}`}>
        電話受付時間 : 15~22時
      </div>
    </main>
  );
}

export default JobInformation;
 */