import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsCoverByRandom } from '../api'; // 假设你有一个这样的 API 方法
import { Image } from 'antd';
import VideoModal from './VideoModal';
import { contact } from '../grocery/utils';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  username: string;
  type?: string;
}

interface CastCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
}

const CastCoverCard: React.FC<CastCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);

  return (
    <div className="rounded-lg overflow-hidden mb-4 w-full">
      <div className="aspect-w-5 aspect-h-7">
        <img
          className="rounded-lg contrast-115 object-cover w-full h-full"
          src={media?.cover}
          alt={media?.username}
          onLoad={() => setImgLoad(true)}
          onError={() => setImgLoad(false)}
          onClick={async () => {
            await setCurrentIndex?.(index);
            setShowPreview?.(true);
          }}
        />
        {imgLoad ? null : <div className='bg-[#d0c9c9] w-full h-full skeleton rounded-lg'></div>}
      </div>
      {showName && (
        <div className="text-white p-2 text-center mt-2">
          <p className="text-sm sm:text-lg">{media?.username}</p>
          <p className="text-xs sm:text-sm text-gray-400">{media?.englishName || ''}</p>
        </div>
      )}
    </div>
  );
};

const CastWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [video, setVideo] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const sort = 'rand'; // 默认排序
    const limit = isMobile ? 15 : 20; // 根据设备类型设置 limit
    getArtistsCoverByRandom({ orderBy: sort, limit, excludedIds }).then((res: any) => {
      const newList = res?.data || [];
      setMediaList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, [isMobile]);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // 初始化检查
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (mediaList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className={`xplore flex flex-col items-center px-4 ${isMobile ? 'mt-12' : 'mt-36'}`}> {/* 添加px-4来增加左右内边距 */}
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className="self-center text-xl font-medium text-center text-orange-800">
        女公關列表
      </div>
      <div className={`self-center text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl ${isMobile ? 'mt-4' : 'mt-12'}`}>
        Cast List
      </div>
      {/* <div className='h-28 lg:h-32'></div> */}
      <div className={`smedia-list grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-4 ${isMobile ? 'mt-6' : 'mt-36'}`}>
        <Image.PreviewGroup
          preview={{
            onVisibleChange: (value) => setShowPreview(value),
            imageRender: (originalNode: React.ReactNode, info: any) => (
              <div className='absolute inset-0 flex flex-col justify-center items-center'>
                <p className='text-white font-montserrat my-2 text-xl md:text-3xl text-center w-full'>
                  {info?.username}
                  <span className='text-sm md:text-xl ml-2 mr-4 md:mr-16'> {info?.englishName}</span>
                </p>
                {originalNode}
                <div className='relative flex items-center my-2 '>
                  <div onClick={() => contact()} className="text-[#FE5D75] mt-2 bg-white rounded-full p-2 md:px-4 md:py-2 text-xl md:text-2xl font-montserrat-extra-bold font-extrabold cursor-pointer">CONTACT US</div>
                </div>
              </div>
            ),
            toolbarRender: (originalNode: React.ReactNode, info: any) => (
              <>{originalNode}</>
            )
          }}
        >
          {mediaList.map((media: any, index: number) => (
            <CastCoverCard
              index={index}
              setCurrentIndex={setCurrentIndex}
              key={"media" + media?.id}
              media={media}
              showName={true}
              setShowPreview={(flag: boolean) => setShowPreview(flag)}
            />
          ))}
        </Image.PreviewGroup>
        {showVideoPreview && video?.videoUrl ? (
          <VideoModal
            setPreviewVisible={setShowVideoPreview}
            previewVisible={showVideoPreview}
            videoUrl={video.videoUrl}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CastWall;

/* import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsCoverByRandom } from '../api'; // 假设你有一个这样的 API 方法
import { Image } from 'antd';
import VideoModal from './VideoModal';
import { contact } from '../grocery/utils';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  username: string;
  type?: string;
}

interface CastCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
}

const CastCoverCard: React.FC<CastCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);

  return (
    <div className="cursor-pointer rounded-lg overflow-hidden mb-4 w-full">
      <div className="aspect-w-5 aspect-h-7">
        <img
          className="rounded-lg contrast-115 object-cover w-full h-full"
          src={media?.cover}
          alt={media?.username}
          onLoad={() => setImgLoad(true)}
          onError={() => setImgLoad(false)}
          onClick={async () => {
            await setCurrentIndex?.(index);
            setShowPreview?.(true);
          }}
        />
        {imgLoad ? null : <div className='bg-[#d0c9c9] w-full h-full skeleton rounded-lg'></div>}
      </div>
      {showName && (
        <div className="text-white p-2 text-center mt-2">
          <p className="text-sm sm:text-lg">{media?.username}</p>
          <p className="text-xs sm:text-sm text-gray-400">{media?.englishName || ''}</p>
        </div>
      )}
    </div>
  );
};

const CastWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [video, setVideo] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const sort = 'rand'; // 默认排序
    const limit = isMobile ? 15 : 20; // 根据设备类型设置 limit
    getArtistsCoverByRandom({ orderBy: sort, limit, excludedIds }).then((res: any) => {
      const newList = res?.data || [];
      setMediaList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, [isMobile]);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // 初始化检查
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (mediaList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className='explore flex flex-col items-center mt-36'>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className="self-center text-xl font-medium text-center text-orange-800">
        女性服务人员列表
      </div>
      <div className="self-center mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Cast List
      </div>
      <div className='h-28 lg:h-32'></div>
      <div className={`media-list grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-4`}>
        <Image.PreviewGroup
          preview={{
            onVisibleChange: (value) => setShowPreview(value),
            imageRender: (originalNode: React.ReactNode, info: any) => (
              <div className='absolute inset-0 flex flex-col justify-center items-center'>
                <p className='text-white font-montserrat my-2 text-xl md:text-3xl text-center w-full'>
                  {info?.username}
                  <span className='text-sm md:text-xl ml-2 mr-4 md:mr-16'> {info?.englishName}</span>
                </p>
                {originalNode}
                <div className='relative flex items-center my-2 '>
                  <div onClick={() => contact()} className="text-[#FE5D75] mt-2 bg-white rounded-full p-2 md:px-4 md:py-2 text-xl md:text-2xl font-montserrat-extra-bold font-extrabold cursor-pointer">CONTACT US</div>
                </div>
              </div>
            ),
            toolbarRender: (originalNode: React.ReactNode, info: any) => (
              <>{originalNode}</>
            )
          }}
        >
          {mediaList.map((media: any, index: number) => (
            <CastCoverCard
              index={index}
              setCurrentIndex={setCurrentIndex}
              key={"media" + media?.id}
              media={media}
              showName={true}
              setShowPreview={(flag: boolean) => setShowPreview(flag)}
            />
          ))}
        </Image.PreviewGroup>
        {showVideoPreview && video?.videoUrl ? (
          <VideoModal
            setPreviewVisible={setShowVideoPreview}
            previewVisible={showVideoPreview}
            videoUrl={video.videoUrl}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CastWall; */

/* import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsCoverByRandom } from '../api'; // 假设你有一个这样的 API 方法
import { Image } from 'antd';
import VideoModal from './VideoModal';
import { contact } from '../grocery/utils';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  username: string;
  type?: string;
}

interface CastCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
}

const CastCoverCard: React.FC<CastCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);

  return (
    <div className="cursor-pointer rounded-lg overflow-hidden mb-4 w-full">
      <img
        className="rounded-lg contrast-115 object-cover w-full h-[150px] sm:h-[200px] md:h-[446px]"
        src={media?.cover}
        alt={media?.username}
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
        onClick={async () => {
          await setCurrentIndex?.(index);
          setShowPreview?.(true);
        }}
      />
      {imgLoad ? null : <div className='bg-[#d0c9c9] w-full h-[150px] sm:h-[200px] md:h-[446px] skeleton rounded-lg'></div>}
      {showName && (
        <div className="text-white p-2 text-center mt-2">
          <p className="text-sm sm:text-lg">{media?.username}</p>
          <p className="text-xs sm:text-sm text-gray-400">{media?.englishName || ''}</p>
        </div>
      )}
    </div>
  );
};

const CastWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [video, setVideo] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const sort = 'rand'; // 默认排序
    const limit = isMobile ? 15 : 20; // 根据设备类型设置 limit
    getArtistsCoverByRandom({ orderBy: sort, limit, excludedIds }).then((res: any) => {
      const newList = res?.data || [];
      setMediaList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, [isMobile]);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // 初始化检查
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (mediaList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className='explore flex flex-col items-center mt-36'>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className="self-center text-xl font-medium text-center text-orange-800">
        女性服务人员列表
      </div>
      <div className="self-center mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Cast List
      </div>
      <div className='h-28 lg:h-32'></div>
      <div className={`media-list grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-4`}>
        <Image.PreviewGroup
          preview={{
            onVisibleChange: (value) => setShowPreview(value),
            imageRender: (originalNode: React.ReactNode, info: any) => (
              <div className='absolute inset-0 flex flex-col justify-center items-center'>
                <p className='text-white font-montserrat my-2 text-xl md:text-3xl text-center w-full'>
                  {info?.username}
                  <span className='text-sm md:text-xl ml-2 mr-4 md:mr-16'> {info?.englishName}</span>
                </p>
                {originalNode}
                <div className='relative flex items-center my-2 '>
                  <div onClick={() => contact()} className="text-[#FE5D75] mt-2 bg-white rounded-full p-2 md:px-4 md:py-2 text-xl md:text-2xl font-montserrat-extra-bold font-extrabold cursor-pointer">CONTACT US</div>
                </div>
              </div>
            ),
            toolbarRender: (originalNode: React.ReactNode, info: any) => (
              <>{originalNode}</>
            )
          }}
        >
          {mediaList.map((media: any, index: number) => (
            <CastCoverCard
              index={index}
              setCurrentIndex={setCurrentIndex}
              key={"media" + media?.id}
              media={media}
              showName={true}
              setShowPreview={(flag: boolean) => setShowPreview(flag)}
            />
          ))}
        </Image.PreviewGroup>
        {showVideoPreview && video?.videoUrl ? (
          <VideoModal
            setPreviewVisible={setShowVideoPreview}
            previewVisible={showVideoPreview}
            videoUrl={video.videoUrl}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CastWall; */




/* import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsCoverByRandom } from '../api'; // 假设你有一个这样的 API 方法
import { Image } from 'antd';
import VideoModal from './VideoModal';
import { contact } from '../grocery/utils';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  username: string;
  type?: string;
}

interface CastCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
}

const CastCoverCard: React.FC<CastCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);

  return (
    <div className="cursor-pointer rounded-lg overflow-hidden mb-4 w-[280px]">
      <img
        className="rounded-lg contrast-115 object-cover w-full h-[446px]"
        src={media?.cover}
        alt={media?.username}
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
        onClick={async () => {
          await setCurrentIndex?.(index);
          setShowPreview?.(true);
        }}
      />
      {imgLoad ? null : <div className='bg-[#d0c9c9] w-full h-[446px] skeleton rounded-lg'></div>}
      {showName && (
        <div className="text-white p-2 text-center mt-2">
          <p className="text-lg">{media?.username}</p>
          <p className="text-sm text-gray-400">{media?.englishName || ''}</p>
        </div>
      )}
    </div>
  );
};

const CastWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [video, setVideo] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const sort = 'rand'; // 默认排序

    getArtistsCoverByRandom({ orderBy: sort, limit: 12, excludedIds }).then((res: any) => {
      const newList = res?.data || [];
      setMediaList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (mediaList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className='explore flex flex-col items-center mt-36'>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className="self-center text-xl font-medium text-center text-orange-800">
        女性服务人员列表
      </div>
      <div className="self-center mt-12 text-6xl font-medium text-orange-100 leading-[77px] max-md:mt-10 max-md:text-4xl">
        Cast List
      </div>
      <div className='h-28 lg:h-32'></div>
      <div className='media-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
        <Image.PreviewGroup
          preview={{
            onVisibleChange: (value) => setShowPreview(value),
            imageRender: (originalNode: React.ReactNode, info: any) => (
              <div className='absolute inset-0 flex flex-col justify-center items-center'>
                <p className='text-white font-montserrat my-2 text-xl md:text-3xl text-center w-full'>
                  {info?.username}
                  <span className='text-sm md:text-xl ml-2 mr-4 md:mr-16'> {info?.englishName}</span>
                </p>
                {originalNode}
                <div className='relative flex items-center my-2 '>
                  <div onClick={() => contact()} className="text-[#FE5D75] mt-2 bg-white rounded-full p-2 md:px-4 md:py-2 text-xl md:text-2xl font-montserrat-extra-bold font-extrabold cursor-pointer">CONTACT US</div>
                </div>
              </div>
            ),
            toolbarRender: (originalNode: React.ReactNode, info: any) => (
              <>{originalNode}</>
            )
          }}
        >
          {mediaList.map((media: any, index: number) => (
            <CastCoverCard
              index={index}
              setCurrentIndex={setCurrentIndex}
              key={"media" + media?.id}
              media={media}
              showName={true}
              setShowPreview={(flag: boolean) => setShowPreview(flag)}
            />
          ))}
        </Image.PreviewGroup>
        {showVideoPreview && video?.videoUrl ? (
          <VideoModal
            setPreviewVisible={setShowVideoPreview}
            previewVisible={showVideoPreview}
            videoUrl={video.videoUrl}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CastWall; */





/* import React, { useState, useEffect, useCallback } from 'react';
import { getArtistsCoverByRandom } from '../api';
import { Image } from 'antd';
import VideoModal from './VideoModal';
import { Masonry } from 'react-masonry-component2';
import { contact } from '../grocery/utils';

interface MediaCover {
  id: number;
  cover: string;
  englishName?: string;
  username: string;
  type?: string;
}

interface CastCoverCardProps {
  media: MediaCover;
  index: number;
  showName?: boolean;
  setShowPreview?: (flag: boolean) => void;
  setCurrentIndex?: (flag: number) => void;
}

const CastCoverCard: React.FC<CastCoverCardProps> = ({ media, index, showName = true, setShowPreview, setCurrentIndex }) => {
  const [imgLoad, setImgLoad] = useState<boolean>(false);
  
  return (
    <div className="cursor-pointer inline-block rounded-lg overflow-hidden mb-0 md:mb-4 w-full">
      <img
        className="rounded-lg contrast-115 object-cover w-full min-w-[42vw] md:min-w-[240px]"
        src={media?.cover}
        alt=''
        onLoad={() => setImgLoad(true)}
        onError={() => setImgLoad(false)}
        onClick={async () => {
          await setCurrentIndex?.(index);
          setShowPreview?.(true);
        }}
      />
      {imgLoad ? null : <div className='bg-[#d0c9c9] w-full pb-[58%] skeleton rounded-lg'></div>}
      {showName ? (
        <div className="backdrop-blur-[2px] md:absolute bottom-0 left-0 right-0 bg-gray-800 bg-opacity-50 text-white p-2 rounded-b-lg">
          <div className="flex justify-between">
            <div>
              <div className="text-[0.8rem] md:text-[14px] text-left font-montserrat text-white p-0 md:pl-2">
                <span>{media?.username}</span>
                <span className='text-[0.6rem] md:text-[12px] ml-2'>{media?.englishName || ''}</span>
              </div>
              <div className="max-w-[16ch] md:max-w-[26ch] text-sm text-left font-montserrat text-white">
                <p>
                  {media?.type?.split(',')?.map((type, index) => (
                    <span
                      key={index}
                      className="text-[10px] md:text-[12px] inline pr-2 font-montserra text-gray-300"
                    >
                      #{type}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const CastWall: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [video, setVideo] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState(0);

  const loadData = useCallback(async () => {
    setLoading(true);
    const excludedIds = [0];
    const sort = 'rand'; // 默认排序

    getArtistsCoverByRandom({ orderBy: sort, limit: 12, excludedIds }).then((res: any) => {
      const newList = res?.data || [];
      setMediaList(newList);
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (mediaList?.length === 0 && (!loading)) {
    return <div className='mt-16'>
      No Data
    </div>
  }

  return (
    <div className='explore'>
      <div className='fixed left-0 top-0 w-[100vw] h-[100vh] bg-black z-[-1]'></div>
      <div className='h-28 lg:h-32'></div>
      <div className='media-list'>
        <Image.PreviewGroup
          preview={{
            onVisibleChange: (value) => setShowPreview(value),
            imageRender: (originalNode: React.ReactNode, info: any) => (
              <div className='absolute inset-0 flex flex-col justify-center items-center'>
                <p className='text-white font-montserrat my-2 text-xl md:text-3xl text-center w-full'>
                  {info?.username}
                  <span className='text-sm md:text-xl ml-2 mr-4 md:mr-16'> {info?.englishName}</span>
                </p>
                {originalNode}
                <div className='relative flex items-center my-2 '>
                  <div onClick={() => contact()} className="text-[#FE5D75] mt-2 bg-white rounded-full p-2 md:px-4 md:py-2 text-xl md:text-2xl font-montserrat-extra-bold font-extrabold cursor-pointer">CONTACT US</div>
                </div>
              </div>
            ),
            toolbarRender: (originalNode: React.ReactNode, info: any) => (
              <>{originalNode}</>
            )
          }}
        >
          {mediaList?.length > 100000
            ? <div className="columns-2 gap-3 md:gap-6 md:columns-3 md:mx-4 lg:columns-4 xl:columns-5 md:max-w-[1200px] lg:max-w-[1440px]">
              {mediaList.map((media: any, index: number) => (
                <CastCoverCard
                  index={index}
                  setCurrentIndex={setCurrentIndex}
                  key={"media" + media?.id}
                  media={media}
                  showName={true}
                  setShowPreview={(flag: boolean) => setShowPreview(flag)}
                />
              ))}
            </div>
            : <Masonry
              direction='row'
              columnsCountBreakPoints={{
                1400: 5,
                1000: 4,
                700: 2,
              }}
            >
              {mediaList.map((media: any, index: number) => (
                <CastCoverCard
                  index={index}
                  setCurrentIndex={setCurrentIndex}
                  key={'media' + media?.id}
                  media={media}
                  showName={true}
                  setShowPreview={(flag: boolean) => setShowPreview(flag)}
                />
              ))}
            </Masonry>
          }
        </Image.PreviewGroup>
        {showVideoPreview && video?.videoUrl ? (
          <VideoModal
            setPreviewVisible={setShowVideoPreview}
            previewVisible={showVideoPreview}
            videoUrl={video.videoUrl}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CastWall;
 */